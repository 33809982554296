/**
 * Props to pass to a `RDCValidationError`.
 */
export interface RDCValidationErrorProps {
  error: string;
}

/**
 * Validation error that should be displayed under form inputs.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCValidationError: React.FC<RDCValidationErrorProps> = (props) => {
  const { error } = props;

  return (
    <p className="h-3 text-sm text-error" role="alert">
      {error}
    </p>
  );
};
