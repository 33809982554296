import { useEffect } from "react";

/**
 * Page that redirects to another page.
 * This is typically used by Webflow pages to "escape" client-side React routing.
 */
export const ForceReloadPage: React.FC = () => {
  useEffect(() => {
    window.location.replace(window.location.href);
  }, []);

  return <></>;
};
