import { AnimatePresence, motion } from "motion/react";
import { useEffect, useState } from "react";
import { EnvelopeIcon, LifebuoyIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { SUPPORT_ROUTE } from "../constants";
import { RDCContactUsModal } from "./RDCContactUsModal";
import { Utils } from "../utils";

const BG_COLOR = "#380089"; // Primary
const FILL_COLOR = "#FFFFFF"; // White
const ASPECT = 32;
const SCALE = 2;
const POPUP_WIDTH = 256;
const CONTACT_US_MODAL_ID_CORNER = "contact-us-modal-corner";

/**
 * Support icon that should be displayed on every page.
 * @returns a React component.
 */
export const RDCSupportIcon: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [blinkKey, setBlinkKey] = useState<number>(0);
  const supportLinks: {
    icon: JSX.Element;
    label: string;
    onClick: () => void;
  }[] = [
    {
      icon: <LifebuoyIcon className="hero-icon hero-icon-sm" />,
      label: "Support",
      onClick: () => navigate(SUPPORT_ROUTE),
    },
    {
      icon: <EnvelopeIcon className="hero-icon hero-icon-sm" />,
      label: "Contact Us",
      onClick: () => Utils.openDialogModal(CONTACT_US_MODAL_ID_CORNER),
    },
  ];

  // Close the popup when the page location changes
  useEffect(() => {
    setShowPopup(false);
  }, [location]);

  // Cause the icon to blink every few seconds when it's open
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (showPopup) {
      interval = setInterval(() => setBlinkKey((prev) => prev + 1), 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [showPopup]);

  // Handle click events on the icon
  const handleIconClick = () => {
    setShowPopup((prev) => !prev);
  };

  return (
    <div className="fixed bottom-5 right-5 z-50 flex flex-col items-end gap-default">
      {/* Contact us modal */}
      <RDCContactUsModal modalId={CONTACT_US_MODAL_ID_CORNER} onSuccess={() => setShowPopup(false)} />
      {/* Popup */}
      <AnimatePresence initial={false}>
        {showPopup && (
          <motion.div
            key="popup"
            className="border-default w-64 overflow-hidden rounded-3xl bg-base-100"
            initial={{ scale: 0, x: POPUP_WIDTH }}
            animate={{ scale: 1, x: 0 }}
            exit={{ scale: 0, x: POPUP_WIDTH }}
          >
            <div className="space-y-default bg-neutral p-4">
              <h2 className="text-base">Welcome! 👋</h2>
              <p className="text-sm text-neutral-content">Select an option to continue</p>
            </div>
            <ul className="space-y-default p-2">
              {supportLinks.map(({ icon, label, onClick }, i) => (
                <button key={i} className="daisy-btn daisy-btn-ghost daisy-btn-sm pr-button" onClick={onClick}>
                  <div className="flex items-center gap-default text-neutral-content">
                    {icon}
                    {label}
                  </div>
                </button>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Animated icon */}
      <button onClick={handleIconClick} aria-label="open support">
        <motion.svg
          className="outline-none"
          height={ASPECT * SCALE}
          width={ASPECT * SCALE}
          viewBox={`0 0 ${ASPECT} ${ASPECT}`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}
          initial="hidden"
          animate="visible"
        >
          <AnimatePresence>
            {/* Background circle */}
            <motion.rect
              key="background-circle"
              width={ASPECT}
              height={ASPECT}
              rx={ASPECT / 2}
              fill={BG_COLOR}
              initial={{ scale: 0 }}
              animate={{ scale: 0.9 }}
            />
            {/* Bottom of E/smile */}
            <motion.path
              key="bottom-icon"
              d="M13.5455 17.6V14.4H7C7 16.1673 8.46525 17.6 10.2727 17.6C10.2727 21.1346 13.2032 24 16.8182 24C18.6257 24 20.0909 22.5673 20.0909 20.8H16.8182C15.0107 20.8 13.5455 19.3673 13.5455 17.6Z"
              fill={FILL_COLOR}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
            />
            {!showPopup && (
              // Top of E
              <motion.path
                key="top-icon"
                d="M20.0909 11.2H10.5521C11.226 9.33569 13.0444 8 15.1818 8H20.0909C22.8021 8 25 10.149 25 12.8C25 15.451 22.8021 17.6 20.0909 17.6H16.8182V14.4H20.0909C20.9946 14.4 21.7273 13.6837 21.7273 12.8C21.7273 11.9163 20.9946 11.2 20.0909 11.2Z"
                fill={FILL_COLOR}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
              />
            )}
            {showPopup && (
              <>
                {/* Eye 1 */}
                <motion.circle
                  key={`eye1-${blinkKey}`}
                  cx="16.6"
                  cy="13"
                  r="1.8"
                  fill={FILL_COLOR}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                />
                {/* Eye 2 */}
                <motion.circle
                  key={`eye2-${blinkKey}`}
                  cx="21.4"
                  cy="13"
                  r="1.8"
                  fill={FILL_COLOR}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0, transition: { delay: 0 } }}
                  transition={{ delay: 0.1 }}
                />
              </>
            )}
          </AnimatePresence>
        </motion.svg>
      </button>
    </div>
  );
};
