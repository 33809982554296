import { useEffect, useState } from "react";
import {
  RDCCustomizedOffersCard,
  RDCFooter,
  RDCNavbar,
  RDCUpdatePasswordForm,
  RDCValidationError,
} from "../components";
import { supabase } from "../supabaseClient";
import { MonthlyQuote, Plan, PLAN_NAMES, Utils } from "../utils";
import WindmillArt from "../images/art/WindmillArt.png";
import {
  BanknotesIcon,
  BuildingOffice2Icon,
  EnvelopeIcon,
  GlobeAmericasIcon,
  KeyIcon,
  PencilIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { SubmitHandler, useForm } from "react-hook-form";
import { ECOTROVE_EMAIL_INFO, EMAIL_REGEX, FULL_NAME_REGEX, LOGIN_ROUTE } from "../constants";
import { useNavigate } from "react-router-dom";
import { constructFullApiURL } from "../constructFullApiUrl";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";

interface InfoForm {
  name: string;
  email: string;
}

/**
 * Page that allows a user to view/update their account settings.
 * @returns
 */
export const RDCAccountSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { setSuccessAlert, setErrorAlert } = useAlertStore();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // User data
  const [userId, setUserId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // Service data
  const [renewableIncrease, setRenewableIncrease] = useState<string | undefined>();
  const [renewableProvider, setRenewableProvider] = useState<string | undefined>();
  // Plan data
  const [activePlan, setActivePlan] = useState<Plan>();
  const [monthlyQuote, setMonthlyQuote] = useState<MonthlyQuote>();
  const [monthlySubscription, setMonthlySubscription] = useState<number | undefined>();
  const [quoteUrl, setQuoteUrl] = useState<string>("");
  // Edit forms
  const [showInfoForm, setShowInfoForm] = useState<boolean>(false);
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
  // Loading states
  const [infoFormLoading, setInfoFormLoading] = useState<boolean>(false);
  const [paymentChangeLoading, setPaymentChangeLoading] = useState<boolean>(false);
  const [planChangeLoading, setPlanChangeLoading] = useState<boolean>(false);

  const infoForm = useForm<InfoForm>();

  useEffect(() => {
    mixpanel.track("accountSettings");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure user is logged in
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user?.id) {
          navigate(LOGIN_ROUTE, { replace: true });
          return;
        }

        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

        setAuthenticated(true);
        setUserId(user.id);

        // Fetch user-profile data
        const { data, error } = await supabase
          .from("user-profiles")
          .select("name,email,stripe_customer_id,monthly_subscription,monthly_quote,quote_url")
          .eq("user_id", user.id)
          .single();
        if (error || !data?.name || !data?.email) {
          throw new Error("an error occurred");
        }
        setName(data.name);
        setEmail(data.email);
        infoForm.setValue("name", data.name);
        infoForm.setValue("email", data.email);

        // Fetch service-accounts data
        const { data: serviceData } = await supabase
          .from("service-accounts")
          .select("renewable_increase,cca_name_manual")
          .eq("user_id", user.id)
          .eq("type", "electricity")
          .single();
        if (serviceData?.renewable_increase) {
          setRenewableIncrease((serviceData.renewable_increase / 100).toFixed(1));
        }
        setRenewableProvider(serviceData?.cca_name_manual ?? undefined);

        // Populate plan and subscription
        setActivePlan(
          Utils.getPlanName({
            stripeCustomerId: data.stripe_customer_id,
            monthlyQuote: data.monthly_quote,
            monthlySubscription: data.monthly_subscription,
          }),
        );
        setMonthlyQuote(data.monthly_quote ?? undefined);
        setMonthlySubscription(data.monthly_subscription ?? undefined);
        setQuoteUrl(data.quote_url ?? "");

        // Add a slight delay to make the loading smoother
        setTimeout(() => setLoading(false), 100);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user. Please try again later.");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Submit handler for info form submit events.
   */
  const handleSubmitInfoForm: SubmitHandler<InfoForm> = async (data) => {
    try {
      setInfoFormLoading(true);

      // Update name
      if (data.name !== name) {
        const { error: userProfilesNameError } = await supabase
          .from("user-profiles")
          .update({ name: data.name })
          .eq("user_id", userId);
        if (userProfilesNameError) throw userProfilesNameError;
      }
      // Update email
      if (data.email !== email) {
        // Update authenticated user
        const { error: updateUserError } = await supabase.auth.updateUser({
          email: data.email,
        });
        if (updateUserError) throw updateUserError;

        // Update user-profiles table
        const { error: userProfilesEmailError } = await supabase
          .from("user-profiles")
          .update({ email: data.email })
          .eq("user_id", userId);
        if (userProfilesEmailError) throw userProfilesEmailError;

        // Update service-accounts table
        const { error: serviceError } = await supabase
          .from("service-accounts")
          .update({ email: data.email })
          .eq("user_id", userId);
        if (serviceError) throw serviceError;

        // Update terms-and-conditions table
        const { error: termsError } = await supabase
          .from("terms-and-conditions")
          .update({ email: data.email })
          .eq("user_id", userId);
        if (termsError) throw termsError;

        // Display message to user
        setSuccessAlert("Please check your inbox to confirm your new email.");
      }

      // Update component states
      setName(data.name);
      setEmail(data.email);
      setShowInfoForm(false);
    } catch (error) {
      setErrorAlert("An error occurred while updating your info. Please try again later.");
    } finally {
      setInfoFormLoading(false);
    }
  };

  /**
   * Handler for update payment events.
   */
  const handleUpdatePayment = async () => {
    try {
      setPaymentChangeLoading(true);
      const apiURL = constructFullApiURL(`/stripe-customer-url`);
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID: userId }),
      });
      const data = await response.json();

      if (data.url) {
        setPaymentChangeLoading(false);
        window.location.href = data.url;
        return;
      }
      throw new Error("Error fetching customer portal session URL");
    } catch (error) {
      setErrorAlert(
        `An error occurred while attempting to change your payment info. Please contact ${ECOTROVE_EMAIL_INFO} if this is an urgent request, or try again later.`,
      );
    }
  };

  /**
   * Handler for plan change events.
   */
  const handleChangePlan = async (plan: Plan) => {
    try {
      setPlanChangeLoading(true);
      await Utils.handleChangePlan({
        navigate,
        email,
        currentPlan: activePlan!,
        currentSubscription: monthlySubscription,
        newPlan: plan,
        monthlyQuote,
        quoteUrl,
      });
      setSuccessAlert("We have been notified of your request to change plans and will contact you shortly. Thank you!");
      // Don't set planChangeLoading to false again (we shouldn't allow the user to spam this feature if we successfully added to the DB)
    } catch (error) {
      if (error && typeof error === "object" && "message" in error && error.message === "unauthenticated") {
        setErrorAlert("You must be signed in to use this feature.");
      } else
        setErrorAlert(
          `An error occurred while attempting to change your plan. Please contact ${ECOTROVE_EMAIL_INFO} if this is an urgent request, or try again later.`,
        );
    }
  };

  return !authenticated ? (
    <></>
  ) : (
    <div className="flex flex-col items-center">
      <RDCNavbar />

      <div className="mt-navbar flex max-w-wide grow flex-col gap-8 p-content-mobile lg:p-content">
        <h1 className="text-2xl">Account Settings</h1>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {/* Customized offers */}
          {loading ? (
            <div className="daisy-skeleton h-56" />
          ) : (
            <div className="order-4 lg:order-1">
              <RDCCustomizedOffersCard
                userData={{
                  activePlan: activePlan!,
                  email,
                  monthlyQuote,
                  monthlySubscription,
                  quoteUrl,
                }}
              />
            </div>
          )}
          {/* Illustration */}
          <div className="order-1 flex size-full lg:order-2">
            <img
              className="grow rounded-3xl object-cover"
              src={WindmillArt}
              alt="windmills and house illustration in purple tone"
            />
          </div>
          {/* Plan */}
          <div className="border-default order-2 flex flex-col gap-4 rounded-3xl p-6 lg:order-3">
            <div className="flex flex-col gap-default">
              <h2 className="text-base text-neutral-content">Your Plan</h2>
              {loading ? <div className="daisy-skeleton h-8" /> : <p className="text-lg">{activePlan}</p>}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-default text-neutral-content">
                <BanknotesIcon className="hero-icon hero-icon-sm" />
                <p>Monthly subscription</p>
              </div>
              {loading ? (
                <div className="daisy-skeleton h-6" />
              ) : (
                <p className={`ml-8 ${monthlySubscription ? "tracking-wide" : ""}`}>
                  {monthlySubscription && activePlan !== "Free Insights"
                    ? `$${monthlySubscription.toFixed(2)}/mo`
                    : "Free"}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-default text-neutral-content">
                <GlobeAmericasIcon className="hero-icon hero-icon-sm" />
                <p>Renewable energy usage</p>
              </div>
              {loading ? (
                <div className="daisy-skeleton h-6" />
              ) : (
                <p className="ml-8">
                  {activePlan === "Free Insights"
                    ? "Use 1.5X cleaner energy by upgrading your plan"
                    : renewableIncrease
                      ? `Using ${renewableIncrease}X cleaner energy`
                      : "We are still calculating your renewable energy increase. Check back soon!"}
                </p>
              )}
            </div>
            {renewableProvider && (
              <div className="flex flex-col">
                <div className="flex items-center gap-default text-neutral-content">
                  <BuildingOffice2Icon className="hero-icon hero-icon-sm" />
                  <p>Your renewable energy provider</p>
                </div>
                {loading ? <div className="daisy-skeleton h-6" /> : <p className="ml-8">{renewableProvider}</p>}
              </div>
            )}
            {activePlan !== "Free Insights" && (
              <button
                className="daisy-btn daisy-btn-neutral w-fit"
                disabled={paymentChangeLoading}
                onClick={handleUpdatePayment}
              >
                Update your payment information
              </button>
            )}
          </div>
          {/* Account */}
          <div className="border-default order-3 flex flex-col gap-4 rounded-3xl p-6 lg:order-4">
            {/* Info form */}
            <form
              className="flex flex-col gap-4"
              onSubmit={infoForm.handleSubmit((data) =>
                handleSubmitInfoForm({
                  ...data,
                  email: data.email.toLowerCase(),
                }),
              )}
            >
              <div className="flex w-full items-center justify-between">
                <h2 className="text-base text-neutral-content">Your Account</h2>
                {!showInfoForm && (
                  <div
                    role="button"
                    className="daisy-btn daisy-btn-circle daisy-btn-ghost text-neutral-content"
                    onClick={() => setShowInfoForm(true)}
                  >
                    <PencilIcon className="hero-icon hero-icon-sm" />
                  </div>
                )}
                {showInfoForm && (
                  <div className="flex gap-default">
                    <button type="submit" className="daisy-btn daisy-btn-primary" disabled={infoFormLoading}>
                      Save
                    </button>
                    <button
                      className="daisy-btn-pneutral daisy-btn"
                      onClick={() => {
                        setShowInfoForm(false);
                        infoForm.setValue("name", name);
                        infoForm.setValue("email", email);
                        infoForm.clearErrors();
                      }}
                      disabled={infoFormLoading}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-default text-neutral-content">
                  <UserCircleIcon className="hero-icon hero-icon-sm" />
                  <p>Full Name</p>
                </div>
                {loading ? (
                  <div className="daisy-skeleton h-6" />
                ) : showInfoForm ? (
                  <>
                    <input
                      type="text"
                      className="daisy-input daisy-input-bordered mb-1 aria-invalid:daisy-input-error aria-invalid:text-error"
                      aria-invalid={Boolean(infoForm.formState.errors.name)}
                      {...infoForm.register("name", {
                        required: true,
                        validate: {
                          matches: (value) => FULL_NAME_REGEX.test(value) || "Please enter your full name",
                        },
                      })}
                    />
                    <RDCValidationError error={infoForm.formState.errors.name?.message ?? ""} />
                  </>
                ) : (
                  <p className="ml-8">{name}</p>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-default text-neutral-content">
                  <EnvelopeIcon className="hero-icon hero-icon-sm" />
                  <p>Email</p>
                </div>
                {loading ? (
                  <div className="daisy-skeleton h-6" />
                ) : showInfoForm ? (
                  <>
                    <input
                      type="text"
                      className="daisy-input daisy-input-bordered mb-1 aria-invalid:daisy-input-error aria-invalid:text-error"
                      aria-invalid={Boolean(infoForm.formState.errors.email)}
                      {...infoForm.register("email", {
                        required: true,
                        validate: {
                          matches: (value) => EMAIL_REGEX.test(value) || "Please enter a valid email",
                        },
                      })}
                    />
                    <RDCValidationError error={infoForm.formState.errors.email?.message ?? ""} />
                  </>
                ) : (
                  <p className="ml-8">{email}</p>
                )}
              </div>
            </form>
            {/* Password form */}
            <div className="flex w-full flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
              {!showPasswordForm && (
                <>
                  <div className="flex flex-col">
                    <div className="flex items-center gap-default text-neutral-content">
                      <KeyIcon className="hero-icon hero-icon-sm" />
                      <p>Password</p>
                    </div>
                    <p className="ml-8">••••••••••••••••</p>
                  </div>
                  <button className="daisy-btn daisy-btn-neutral w-fit" onClick={() => setShowPasswordForm(true)}>
                    Change Password
                  </button>
                </>
              )}
              {showPasswordForm && (
                <div className="flex w-full flex-col gap-4">
                  <RDCUpdatePasswordForm
                    onSuccess={() => {
                      setSuccessAlert("Your password has been successfully updated.");
                      setShowPasswordForm(false);
                    }}
                    onError={() =>
                      setErrorAlert("An error occurred while updating your password. Please try again later.")
                    }
                    submitButtonClass="lg:w-fit"
                  />
                  <button className="daisy-btn daisy-btn-neutral lg:w-fit" onClick={() => setShowPasswordForm(false)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Plans */}
        <h2 className="text-lg text-neutral-content">Plans</h2>
        <div className="-mt-4 grid grid-cols-1 gap-6 lg:grid-cols-3">
          {PLAN_NAMES.map((plan, i) => {
            const planQuote = monthlyQuote ? Utils.getQuoteByPlan(plan, monthlyQuote) : undefined;
            const isUpgrade = activePlan === "Free Insights" || (activePlan === "Max Saver" && plan === "Green Saver");
            return loading ? (
              <div key={i} className="daisy-skeleton h-64" />
            ) : (
              <div key={i} className="border-default flex flex-col gap-4 rounded-3xl p-6">
                <div className="flex items-center gap-default">
                  <h3 className="text-xl">{plan}</h3>
                  {plan === activePlan && <div className="daisy-badge daisy-badge-neutral">Active Plan</div>}
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-default text-neutral-content">
                    <BanknotesIcon className="hero-icon hero-icon-sm" />
                    <p>Monthly subscription</p>
                  </div>
                  <p className={`ml-8 ${plan !== "Free Insights" && planQuote ? "tracking-wide" : ""}`}>
                    {plan === "Free Insights"
                      ? `$0/mo (${activePlan !== "Free Insights" ? "resume " : ""}paying PG&E directly)`
                      : plan === activePlan
                        ? `$${monthlySubscription}/mo`
                        : planQuote
                          ? `$${planQuote}/mo`
                          : "Check with us for a quote"}
                  </p>
                </div>
                {plan !== activePlan && (
                  <button
                    className={`daisy-btn ${isUpgrade ? "daisy-btn-secondary" : "daisy-btn-neutral"}`}
                    disabled={planChangeLoading}
                    onClick={() => handleChangePlan(plan)}
                  >
                    {isUpgrade ? "Upgrade" : "Downgrade"}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <RDCFooter showExplainer={activePlan === "Free Insights"} />
    </div>
  );
};
