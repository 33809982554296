import React, { useState, useEffect } from "react";

export const RDCProgressBar: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const duration = 72 * 1000; //1.2 minutes
    const easing = (t: number) => 1 - Math.pow(1 - t, 2); // Quadratic easing-out function

    const start = Date.now();
    const interval = setInterval(() => {
      const elapsed = Date.now() - start;
      const t = Math.min(elapsed / duration, 1); // Normalize elapsed time to [0, 1]
      const easedProgress = easing(t) * 99.9; // Scale to 99.9%

      setProgress(easedProgress);

      if (t === 1) clearInterval(interval); // Stop when progress reaches 100%
    }, 100); // Update every 100ms

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative mx-auto mt-6 w-full max-w-lg">
      {/* Progress Bar */}
      <progress className="daisy-progress daisy-progress-primary w-full" value={progress} max="99" />

      {/* Progress Text */}
      <div className="mt-2 flex items-center justify-between">
        <span className="text-sm text-gray-600">Loading</span>
        <span className="text-sm text-gray-600">{Math.round(progress)}%</span>
      </div>
    </div>
  );
};
