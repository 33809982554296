import { PropsWithChildren } from "react";
import { RDCEcoTroveLogoLink } from "../RDCEcoTroveLogoLink";
import { Utils } from "../../utils";

/**
 * Props to pass to a `CardWithBackground`.
 */
export interface CardWithBackgroundProps {
  /** Optionally apply a className to the content. */
  className?: string;
}

/**
 * Component that renders child content inside a centered card, with custom EcoTrove artwork in the background.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const CardWithBackground: React.FC<PropsWithChildren & CardWithBackgroundProps> = (props) => {
  const { children, className } = props;
  return (
    <>
      {/* Background images */}
      <div className="fixed flex h-lvh w-lvw flex-col justify-between bg-accent lg:flex-row">
        <img
          className="hidden h-full lg:flex"
          src={Utils.getPublicPath("images/art/PlantLeft.svg")}
          alt="plant with leaves"
        />
        <img className="h-full" src={Utils.getPublicPath("images/art/WindTurbineRight.svg")} alt="wind turbine" />
      </div>
      {/* EcoTrove logo + card */}
      <div className="flex w-full place-content-center p-content-mobile lg:px-content lg:py-8">
        <div className="z-10 flex w-full flex-col items-center gap-4">
          <header>
            <RDCEcoTroveLogoLink variant="primary" />
          </header>
          <div className={`w-full max-w-3xl space-y-8 rounded-3xl bg-base-100 p-6 ${className}`}>{children}</div>
        </div>
      </div>
    </>
  );
};
