import { Link } from "./types";
import * as tailwindColors from "tailwindcss/colors";

/* ---- ECOTROVE ---- */
export const ECOTROVE_URL = "https://ecotrove.com";
export const ECOTROVE_EMAIL_INFO = "info@ecotrove.com";
export const ECOTROVE_TEST_EMAIL = "info@ecotrove.com";
export const ECOTROVE_FACEBOOK_URL = "https://www.facebook.com/people/EcoTrove/61553413146538/";
export const ECOTROVE_INSTAGRAM_URL = "https://www.instagram.com/ecotrove_energy/";
export const ECOTROVE_TWITTER_URL = "https://x.com/ecotrove_energy";
export const ECOTROVE_LINKEDIN_URL = "https://www.linkedin.com/company/ecotrove";
export const ECOTROVE_TIKTOK_URL = "https://www.tiktok.com/@ecotrove_energy";
export const ECOTROVE_TRUSTPILOT_URL = "https://www.trustpilot.com/review/ecotrove.com";

/* ---- URLS ---- */
export const DATAGUARD_URL = "https://www.energy.gov/oe/dataguard-energy-data-privacy-program";
export const COMODOSSL_URL = "https://comodosslstore.com/about-us.aspx";
/** Link to Calendly for 15 minute feedback. */
export const CALENDLY_FEEDBACK_URL = "https://calendly.com/ali-sarilgan/ecotrove-call";
/** Link to Calendly for 30 minute demo. */
export const CALENDLY_DEMO_URL = "https://calendly.com/ali-sarilgan/ecotrove-demo";

/* ---- BUSINESS LOGIC ---- */
export const REFERRAL_DISCOUNT = 50;
export const FEEDBACK_DISCOUNT = 20;

/* ---- ROUTES ---- */
export const HOW_IT_WORKS_ROUTE = "/how-it-works";
export const ABOUT_US_ROUTE = "/about-us";
export const BLOGS_ROUTE = "/blog";
export const PGE_OAUTH_REDIRECT_ROUTE = "/pge-oauth-redirect";
export const CUSTOMIZE_QUOTE_ROUTE = "/customize-quote";
export const PAYMENT_SUCCESS_ROUTE = "/payment-success";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const TERMS_OF_SERVICE_ROUTE = "/terms-of-service";
export const AGREE_TO_TERMS_ROUTE = "/terms-and-conditions";
export const USER_DASHBOARD_ROUTE = "/user-dashboard";
export const USER_QUESTIONS_ROUTE = "/user-questions";
export const ACCOUNT_SETTINGS_ROUTE = "/account-settings";
export const SHOW_QUOTE_ROUTE = "/show-quote";
export const SHOW_SOLAR_QUOTE_ROUTE = `${SHOW_QUOTE_ROUTE}/solar`;
export const SHOW_BUSINESS_QUOTE_ROUTE = `${SHOW_QUOTE_ROUTE}/business`;
export const SHOW_RESIDENTIAL_QUOTE_ROUTE = `${SHOW_QUOTE_ROUTE}/residential`;
export const THANKS_FOR_REAUTHORIZING_ROUTE = "/thanks-for-reauthorizing";
export const LOGIN_ROUTE = "/login";
export const CREATE_ACCOUNT_ROUTE = "/create-account";
export const UPDATE_PASSWORD_ROUTE = "/update-password";
export const CONFIRM_EMAIL_ROUTE = "/confirm-email";
export const SUPPORT_ROUTE = "/support";
export const UTILITY_LOGIN_ROUTE = "/utility-login";
export const PARTNER_ROUTE = "/partner";
export const REFER_ROUTE = "/refer";

// Status code routes
export const STATUS_400_ROUTE = "/400";
export const STATUS_403_ROUTE = "/403";

/* ---- JSX ---- */
/**
 * Space to add at the bottom on mobile so the next view doesn't get snapped too soon.
 */
export const MOBILE_SNAP_SPACER = <div className="flex h-48 lg:hidden" />;

/* ---- REGEX ---- */

/**
 * Regex to validate a person's name (from https://regexr.com/3f8cm).
 */
export const FULL_NAME_REGEX = /^\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+$/;

/**
 * Regex to validate an email (from https://regexr.com/3e48o).
 * IMPORTANT: This regex does NOT validate case sensitivity. Therefore, emails should be formatted as lower case before storing.
 */
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

/**
 * Regex to validate a 5-digit ZIP code.
 */
export const ZIP_CODE_REGEX = /^\d{5}$/;

/* ---- ECHARTS ---- */
/**
 * TODO: import theme colors from Tailwind config.
 * I tried this guide without success: https://stackoverflow.com/questions/72440619/import-styles-directly-from-tailwindcss-config-file
 * I suspect our preflight plugin is messing with the imports.
 * Some example theme configs can be found here: https://github.com/apache/echarts/tree/master/theme
 */
export const ECHARTS_PRIMARY = "#380089";
export const ECHARTS_SECONDARY = "#DBD9EC";
export const ECHARTS_NEUTRAL = tailwindColors.stone[200];
export const ECHARTS_NEUTRAL_CONTENT = tailwindColors.stone[600];
export const ECHARTS_TEXT_STYLE = {
  color: tailwindColors.gray[950],
  fontFamily: "Gilroy-Medium",
  fontSize: 18,
};
export const ECHARTS_THEME = {
  textStyle: ECHARTS_TEXT_STYLE,
  color: [ECHARTS_PRIMARY, ECHARTS_SECONDARY, ECHARTS_NEUTRAL, ECHARTS_NEUTRAL_CONTENT],
  categoryAxis: { axisLabel: ECHARTS_TEXT_STYLE },
  valueAxis: { axisLabel: ECHARTS_TEXT_STYLE },
};
export const ECHARTS_BAR_STYLE = {
  borderRadius: [5, 5, 0, 0],
};

/* ---- GOOGLE ADS ---- */
export const GA_CONVERSION_ID_GET_STARTED_CLICK = "AW-16704324082/OWvwCKT3rI0aEPKDn50-";
export const GA_CONVERSION_ID_SCHEDULE_CALL_CLICK = "AW-16704324082/VNVmCKf3rI0aEPKDn50-";
export const GA_CONVERSION_ID_GET_QUOTE_PAGE = "AW-16704324082/GwAYCJ73rI0aEPKDn50-";
export const GA_CONVERSION_ID_SHOW_QUOTE_PAGE = "AW-16704324082/A7BZCKH3rI0aEPKDn50-";

/* ---- SUPABASE ---- */
export const SUPABASE_UNAUTHENTICATED_ERROR_CODE = "42501";

/* ---- LINK CONFIGS ---- */
/**
 * Links that are shown in main navigation menus for authenticated users.
 */
export const MAIN_MENU_LINKS_AUTH: Link[] = [
  { label: "Dashboard", url: USER_DASHBOARD_ROUTE },
  { label: "Account Settings", url: ACCOUNT_SETTINGS_ROUTE },
  { label: "Support", url: SUPPORT_ROUTE },
];

/**
 * Links that are shown in main navigation menus for unauthenticated users.
 */
export const MAIN_MENU_LINKS_UNAUTH: Link[] = [
  { label: "How It Works", url: HOW_IT_WORKS_ROUTE },
  { label: "About Us", url: ABOUT_US_ROUTE },
  { label: "Blog", url: BLOGS_ROUTE },
];

/**
 * Links that prompt a user to take action.
 */
export const CTA_LINKS = {
  unauthenticated: undefined,
  authenticated: { label: "Refer a Friend", url: REFER_ROUTE },
};

/* ---- FAQs ---- */
/** FAQs that should be shown in a "how it works" context. */
export const HOW_IT_WORKS_FAQS: { q: string; a: string }[] = [
  {
    q: "How does EcoTrove’s payment plan work?",
    a: "You pay EcoTrove a predictable, fixed price every month and we pay your electricity and natural gas bills with your utility on your behalf. You can still monitor your power bills and make changes to your utility account, but you stop paying your utility.",
  },
  {
    q: "How can EcoTrove guarantee a fixed price?",
    a: "Our proprietary technology forecasts how much power you will need in the following year, identifies the cheapest possible green energy options, most appropriate rate plans and government incentives applicable to you. We accept the risk of price fluctuations on your behalf so that you can use the power you need, without worrying about large bill swings.",
  },
  {
    q: "Does EcoTrove cover both electricity and natural gas?",
    a: "Yes, your EcoTrove payment plan covers both electricity and natural gas. EcoTrove automatically switches your electricity source to a renewable energy generator. Since there is no renewable energy option to power your gas-appliances (gas stove, gas heater etc.), you will continue receiving natural gas without interruption.",
  },
  {
    q: "What is EcoTrove's relationship with PG&E?",
    a: "EcoTrove is a private company, separate from PG&E. EcoTrove is an approved PG&E third-party service provider, with third-party ID 51684. EcoTrove's subscriber agreement includes a tri-party contract between EcoTrove, the EcoTrove customer, and PG&E which allows EcoTrove to act on the customer's behalf and pay bills with PG&E. EcoTrove customers continue to own their PG&E accounts to ensure there is no disruption in service if they decide to stop using EcoTrove, but they no longer pay PG&E bills and paying for PG&E bills becomes EcoTrove's responsibility.",
  },
  {
    q: "How does EcoTrove switch me to clean energy?",
    a: "EcoTrove identifies the cheapest renewable energy generator available in your area. This typically corresponds to a utility-approved Community Choice Aggregator or a community solar provider. We contact the renewable energy generator and your utility on your behalf, and request them to immediately switch your electricity source to a cleaner option.",
  },
  {
    q: "What happens if I start using less energy?",
    a: "EcoTrove reserves the right to reward you for using less energy. For every month where you use less electricity or natural gas compared to the same month last year, EcoTrove can issue a discount to your account.",
  },
  {
    q: "What is your cancellation policy?",
    a: "You can cancel at any time by emailing us at info@ecotrove.com or on your account, and paying your last due subscription price to EcoTrove. If you decide to cancel due to EcoTrove raising prices of your subscription, then your last month’s utility bill is entirely on us.",
  },
  {
    q: "This sounds too good to be true - how on earth do you make money?",
    a: "EcoTrove's purpose is to make clean energy affordable and accessible. Our first priority is to ensure you use all the power you need with guaranteed fixed prices that are lower. By automatically analyzing all available energy data and incentives, EcoTrove's algorithms can take any household or small business power bill and drastically reduce it. We pass on the majority of the savings we find to you, and any additional saving we are able to secure above and beyond becomes our profit.",
  },
];

/** FAQs that should be shown in a support context. */
export const SUPPORT_FAQS: { q: string; a: string }[] = [
  {
    q: "How does EcoTrove’s payment plan work?",
    a: "You pay EcoTrove a predictable, fixed price every month and we pay your electricity and natural gas bills with your utility on your behalf. You can still monitor your power bills and make changes to your utility account, but you stop paying your utility.",
  },
  {
    q: "This sounds too good to be true - how on earth do you make money?",
    a: "EcoTrove's purpose is to make clean energy affordable and accessible. Our first priority is to ensure you use all the power you need with guaranteed fixed prices that are lower. By automatically analyzing all available energy data and incentives, EcoTrove's algorithms can take any household or small business power bill and drastically reduce it. We pass on the majority of the savings we find to you, and any additional saving we are able to secure above and beyond becomes our profit.",
  },
  {
    q: "How can EcoTrove guarantee a fixed price?",
    a: "Our proprietary technology forecasts how much power you will need in the following year, identifies the cheapest possible green energy options, most appropriate rate plans and government incentives applicable to you. We accept the risk of price fluctuations on your behalf so that you can use the power you need, without worrying about large bill swings.",
  },
  {
    q: "Does EcoTrove cover both electricity and natural gas?",
    a: "Yes, your EcoTrove payment plan covers both electricity and natural gas. EcoTrove automatically switches your electricity source to a renewable energy generator. Since there is no renewable energy option to power your gas-appliances (gas stove, gas heater etc.), you will continue receiving natural gas without interruption.",
  },
  {
    q: "How does EcoTrove switch me to clean energy?",
    a: "EcoTrove identifies the cheapest renewable energy generator available in your area. This typically corresponds to a utility-approved Community Choice Aggregator or a community solar provider. We contact the renewable energy generator and your utility on your behalf, and request them to immediately switch your electricity source to a cleaner option.",
  },
  {
    q: "What is your cancellation policy?",
    a: "You can cancel at any time by emailing us at info@ecotrove.com or on your account, and paying your last due subscription price to EcoTrove. If you decide to cancel due to EcoTrove raising prices of your subscription, then your last month’s utility bill is entirely on us.",
  },
  {
    q: "What is EcoTrove's relationship with PG&E?",
    a: "EcoTrove is a private company, separate from PG&E. EcoTrove is an approved PG&E third-party service provider, with third-party ID 51684. EcoTrove's subscriber agreement includes a tri-party contract between EcoTrove, the EcoTrove customer, and PG&E which allows EcoTrove to act on the customer's behalf and pay bills with PG&E. EcoTrove customers continue to own their PG&E accounts to ensure there is no disruption in service if they decide to stop using EcoTrove, but they no longer pay PG&E bills and paying for PG&E bills becomes EcoTrove's responsibility.",
  },
];
