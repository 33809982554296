import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface PartnerState {
  partner: string | undefined;
  setPartner: (partner: string) => void;
}

/**
 * Global store for partner state; this is used to track partners that refer users to EcoTrove.
 */
export const usePartnerStore = create<PartnerState>()(
  persist(
    (set) => ({
      partner: undefined,
      setPartner: (partner) => set({ partner }),
    }),
    {
      name: "partner-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
