import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RDCActionCard, RDCPricingCard, RDCSavingsWaterfall, TitleBadge } from "../components";
import mixpanel from "mixpanel-browser";
import { supabase } from "../supabaseClient";
import {
  CUSTOMIZE_QUOTE_ROUTE,
  STATUS_403_ROUTE,
  GA_CONVERSION_ID_SHOW_QUOTE_PAGE,
  GA_CONVERSION_ID_SCHEDULE_CALL_CLICK,
  GA_CONVERSION_ID_GET_STARTED_CLICK,
  CALENDLY_DEMO_URL,
} from "../constants";
import { useAlertStore } from "../stores";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { Utils } from "../utils";
import { GoogleAdsService } from "../services";

/**
 * Page that displays a quote to a user when they are first signing up.
 */
export const RDCShowQuotePage: React.FC = () => {
  const navigate = useNavigate();
  const { setErrorAlert } = useAlertStore();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [paramsValid, setParamsValid] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [pricingOptions, setPricingOptions] = useState<any[]>([]);
  const [currentMonthlyBill, setCurrentMontlyBill] = useState<number>();
  const [maxSaverMonthlyBill, setMaxSaverMonthlyBill] = useState<number>();
  const [savings, setSavings] = useState<number | null>(null);
  const [percentSavings, setPercentSavings] = useState<number | null>(null);
  const [isMUD, setIsMUD] = useState(false);
  const [insufficientData, setInsufficientData] = useState(false);

  const taskId = searchParams.get("taskId");
  const email = searchParams.get("email");

  useEffect(() => {
    // Track analytics
    mixpanel.track("showQuote");
    GoogleAdsService.event(GA_CONVERSION_ID_SHOW_QUOTE_PAGE);

    // If the user is already paying, they should not be able to access this page
    const checkAuthorized = async () => {
      if (email && !(await Utils.canGetQuote(email))) {
        navigate(STATUS_403_ROUTE, { replace: true });
      } else {
        setAuthorized(true);
      }
    };
    checkAuthorized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      // Confirm that the necessary params are present
      if (!taskId || !email) {
        setErrorAlert("Session expired.");
        setLoading(false);
        return;
      }

      // Confirm that the taskId is valid
      const { data, error } = await supabase.from("task-results").select("result").eq("task_id", taskId).single();
      if (error) {
        setErrorAlert("Unable to fetch task results.");
        setLoading(false);
        return;
      }
      setParamsValid(true);

      // Opt users into free monitoring emails
      // We shouldn't display an error if this fails, but rather log a metric so we will have visibility into it
      const { error: freeInsightError } = await supabase
        .from("user-profiles")
        .update({ free_monitoring: true })
        .eq("email", email);
      if (freeInsightError) mixpanel.track(`Supabase error setting free_monitoring field: ${freeInsightError.code}`);

      const result = data?.result || {};
      if (result.message === "Insufficient Data") {
        let pricingOptions = generatePricingOptions({}, {}, 0);
        setInsufficientData(true);
        pricingOptions = addOnClickHandlers(pricingOptions, email, supabase, navigate, setErrorAlert);
        setPricingOptions(pricingOptions);
        setLoading(false);
      } else {
        const parsed = {
          message: result.message,
          monthlyQuote: result.monthlyQuote,
          renewablePercents: result.averageRenewablePercent,
          averageBill: result.averageBill,
          highestBill: result.highestBill,
          gasUsageByMonth: result.gasUsageByMonth,
          electricityUsageByMonth: result.electricityUsageByMonth,
          isMUD: result.isMUD,
        };
        setIsMUD(isMUD);

        // Dynamically generate pricing options
        let pricingOptions = generatePricingOptions(parsed.monthlyQuote, parsed.renewablePercents, parsed.averageBill);
        // Add onClick handlers
        pricingOptions = addOnClickHandlers(pricingOptions, email, supabase, navigate, setErrorAlert);
        setPricingOptions(pricingOptions);

        // Calculate savings and percentSavings
        const maxSaverPrice = pricingOptions.find((option) => option.title === "Max Saver")?.price;
        const maxSaverMonthlyPrice = maxSaverPrice ? parseFloat(maxSaverPrice.replace(/[^\d.]/g, "")) : null;

        if (maxSaverMonthlyPrice && parsed.averageBill) {
          const monthlySavings = parsed.averageBill * 1.03 - maxSaverMonthlyPrice;
          const savings = Math.round(monthlySavings * 12); // Annual savings
          const percentSavings = Math.round((monthlySavings / parsed.averageBill) * 100);

          setSavings(savings);
          setPercentSavings(percentSavings);

          // Set bill states to be used in graph
          setCurrentMontlyBill(parsed.averageBill);
          setMaxSaverMonthlyBill(maxSaverMonthlyPrice);
        }

        setLoading(false);
      }
    };

    fetchResults();

    const quoteURL = window.location.href;

    // Upload quote url to supabase to be used later
    if (process.env.REACT_APP_TESTING !== "true") {
      const supabasePromise = supabase.from("user-profiles").update({ quote_url: quoteURL }).eq("email", email);
      supabasePromise.then(({ error }) => {
        if (error) mixpanel.track(`Supbase error setting quote_url field: ${error.code}`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, email]);

  const generatePricingOptions = (monthlyQuote: any, renewablePercents: any, averageBill: number) => {
    const options = [];

    const titleBadges: Record<string, TitleBadge> = {
      maxSaver: { text: "Maximize savings", type: "neutral" },
      greenSaver: { text: "100% clean energy", type: "secondary" },
    };

    const getPriceClarification = (title: string, price: number) => {
      switch (title) {
        case "Max Saver":
          return `Replaces ~$${(price * 1.045).toFixed(0)}/mo. average PG&E bills`;
        case "Green Saver":
          return `Replaces ~$${(price * 1.045).toFixed(0)}/mo. average PG&E bills`;
        default:
          return "";
      }
    };

    // Static descriptions and features for each plan
    const descriptions = {
      maxSaver: "Our cheapest plan. Save while using at least 50% green energy.",
      greenSaver: "Ideal for eco-conscious individuals who want to use 100% clean energy.",
    };

    const staticFeatures = {
      maxSaver: [
        "{annualSavings}",
        "Monthly energy insights",
        "{percentRenewable}% renewable energy",
        "EcoTrove pays your utility bill",
      ],
      greenSaver: [
        "{annualSavings}",
        "Monthly energy insights",
        "{percentRenewable}% renewable energy",
        "EcoTrove pays your utility bill",
      ],
    };

    const createOption = (
      title: string,
      titleBadge: TitleBadge | undefined,
      price: string,
      percentRenewable: number,
      description: string,
      features: string[],
      isHighlighted: boolean,
    ) => {
      const priceValue = parseFloat(price.replace(/[^\d.]/g, "")); // Extract numerical value from price string
      const savings = averageBill * 1.045 - priceValue; // Calculate savings
      const annualSavings = savings * 12;

      let savingsText = "";
      if (savings < 0 && title === "Green Saver") {
        savingsText = `$${Math.abs(savings).toFixed(0)} more a month than PG&E to use 100% green energy`;
      } else {
        savingsText = `Save $${annualSavings.toFixed(0)} annually or $${(annualSavings / 12).toFixed(0)} monthly`;
      }

      const updatedFeatures = features.map((feature) =>
        feature.replace("{percentRenewable}", percentRenewable.toString()).replace("{annualSavings}", savingsText),
      );

      return {
        title,
        titleBadge,
        priceClarification: getPriceClarification(title, averageBill),
        price,
        savings: `$${savings.toFixed(2)}`, // Format savings to 2 decimal places
        description,
        features: updatedFeatures,
        ctaText: "Get Started",
        isHighlighted,
      };
    };

    // Extract saver and super plans with renewable percents
    if (monthlyQuote.saver && monthlyQuote.super) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.saver}/mo`,
          renewablePercents?.saver || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true,
        ),
      );
      options.push(
        createOption(
          "Green Saver",
          titleBadges.greenSaver,
          `$${monthlyQuote.super}/mo`,
          renewablePercents?.super || 0, // Use renewablePercents for Green Saver
          descriptions.greenSaver,
          staticFeatures.greenSaver,
          false,
        ),
      );
    } else if (monthlyQuote.super) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.super}/mo`,
          renewablePercents?.super || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true,
        ),
      );
    } else if (monthlyQuote.saver) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.saver}/mo`,
          renewablePercents?.saver || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true,
        ),
      );
    } else if (monthlyQuote.pge) {
      options.push(
        createOption(
          "Max Saver",
          titleBadges.maxSaver,
          `$${monthlyQuote.pge}/mo`,
          renewablePercents?.pge || 0, // Use renewablePercents for Max Saver
          descriptions.maxSaver,
          staticFeatures.maxSaver,
          true,
        ),
      );
    }

    return options;
  };

  const addOnClickHandlers = (
    pricingOptions: any[],
    email: string | null,
    supabase: any,
    navigate: any,
    setErrorAlert: (message: string) => void,
  ) => {
    return pricingOptions.map((option) => {
      if (option.title === "Max Saver" || option.title === "Green Saver") {
        return {
          ...option,
          onClick: async () => {
            GoogleAdsService.event(GA_CONVERSION_ID_GET_STARTED_CLICK);
            mixpanel.track(`Choose plan: ${option.title}`);
            if (!email) {
              setErrorAlert("Error: Session Expired.");
              return;
            }

            const monthlyPrice = parseFloat(option.price.replace(/[^\d.]/g, ""));
            const { error } = await supabase
              .from("user-profiles")
              .update({
                monthly_subscription: monthlyPrice,
                accepted_quote: true,
                subscription_type: option.title,
              })
              .eq("email", email);
            if (error)
              mixpanel.track(`Supabase error setting monthly_subscription and/or accepted_quote fields: ${error}`);

            const params = new URLSearchParams({ email });
            const customizeQuoteUrl = `${CUSTOMIZE_QUOTE_ROUTE}?${params.toString()}`;
            navigate(customizeQuoteUrl);
          },
        };
      }

      return option; // Return unchanged option if it doesn't match any conditions
    });
  };

  return !authorized || !paramsValid ? (
    <></>
  ) : (
    <div className="flex min-h-dvh flex-col items-center">
      {/* Main content */}
      <div className="flex max-w-wide flex-col items-center justify-center p-content-mobile lg:p-content">
        {loading ? (
          <div className="daisy-loading daisy-loading-spinner text-primary"></div>
        ) : (
          <>
            {savings && percentSavings ? (
              <div className="flex w-full flex-col items-center gap-4 text-center">
                <h1 className="text-sm text-black lg:text-lg">Congratulations! 🎉</h1>
                <h1 className="text-xl font-bold text-black lg:text-2xl">
                  {isMUD ? (
                    <div>
                      You can save up to{" "}
                      <span className="text-2xl font-bold text-primary">
                        ${savings} ({percentSavings}%)
                      </span>{" "}
                      per year with EcoTrove's fixed plans
                    </div>
                  ) : (
                    <div>
                      You can save up to <span className="text-2xl font-bold text-primary">${savings}</span> per year
                      with EcoTrove's fixed plans
                    </div>
                  )}
                </h1>
                {isMUD ? (
                  <p className="w-2/3">
                    We see that you recently moved to a new address - congratulations! We are happy to offer you a quote
                    based on our proprietary forecasts. Please understand that our forecasts might be inaccurate. If you
                    use less energy than we predicted, you will receive a discount on your subscription. If you use more
                    energy than predicted, we will offer you a new quote.
                  </p>
                ) : (
                  <h2 className="text-center text-lg text-black">
                    Switch to our flat-rate plans and you can save up to{" "}
                    <span className="text-lg text-primary">{percentSavings}%</span> while protecting yourself from
                    rising costs.
                  </h2>
                )}
                {currentMonthlyBill && maxSaverMonthlyBill && (
                  <div className="my-4 w-full">
                    <RDCSavingsWaterfall
                      isMUD={isMUD}
                      monthlyBillBefore={currentMonthlyBill}
                      monthlyBillAfter={maxSaverMonthlyBill}
                    />
                  </div>
                )}
              </div>
            ) : insufficientData ? (
              <div>
                {" "}
                Your account is too new for us to forecast your energy demand! We will send you insights and notify you
                as soon as you are eligible for a paid plan.{" "}
              </div>
            ) : (
              <div>Continue saving!</div>
            )}

            <div
              className={`grid w-full grid-cols-1 ${`lg:grid-cols-${
                // Length + 1 is used here to add an spot for the "schedule a call" card
                pricingOptions.length + 1
              }`} mt-2 justify-center gap-6`}
            >
              {pricingOptions.map((option, i) => (
                <RDCPricingCard
                  key={i}
                  title={option.title}
                  titleBadge={option.titleBadge}
                  price={option.price}
                  priceClarification={option.priceClarification}
                  description={option.description}
                  features={option.features}
                  ctaText={option.ctaText}
                  isHighlighted={option.isHighlighted}
                  onClick={option.onClick}
                />
              ))}
              <RDCActionCard
                title="Not Sure Yet?"
                subTitle="Schedule a free consultation call with one of our energy experts."
                body={
                  <div className="flex w-full flex-col items-center">
                    <CalendarDaysIcon className="hero-icon size-32 self-center text-neutral-content" />
                  </div>
                }
                action={{
                  type: "link",
                  text: "Schedule Call",
                  href: CALENDLY_DEMO_URL,
                  onClick: () => {
                    mixpanel.track("Click schedule call on show quote");
                    GoogleAdsService.event(GA_CONVERSION_ID_SCHEDULE_CALL_CLICK);
                  },
                }}
              />
            </div>
            {/* Footer Message */}
            <div className="mx-auto mt-8 w-full">
              {pricingOptions.length > 0 && (
                <p className="whitespace-normal text-left text-sm leading-relaxed text-gray-600">
                  If you select a paid plan, you will stop paying PG&E and authorize EcoTrove to pay your PG&E
                  electricity and gas (if applicable) bills, secure discounts, and request shifts to renewable energy on
                  your behalf.
                  <br />
                  <br />
                  EcoTrove will bill you in{" "}
                  <span className="daisy-text-primary">
                    {pricingOptions.find((option) => option.title === "Max Saver")?.price || "$0/mo"}
                  </span>{" "}
                  (incl. taxes) monthly installments for the next 12 months and cover your next 12 PG&E bills. Unless
                  you select a later start date on the next step, your subscription will begin tomorrow. You might be
                  rewarded discounts for decreases in energy usage and offered a higher subscription price to accept or
                  reject if your usage increases substantially.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
