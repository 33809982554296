import EcoTroveLogo from "../images/logos/EcoTroveLogoStandard.svg";
import EcoTroveLogoPrimary from "../images/logos/EcoTroveLogoPrimary.svg";
import { Link } from "react-router-dom";

const CONFIG: Record<LogoVariant, { logo: string; textClass: string }> = {
  base: { logo: EcoTroveLogo, textClass: "text-base" },
  primary: { logo: EcoTroveLogoPrimary, textClass: "text-primary" },
};

/** Color variant of the EcoTrove logo. */
export type LogoVariant = "base" | "primary";

/**
 * Props to pass to a `RDCEcoTroveLogoLink`.
 */
export interface RDCEcoTroveLogoLinkProps {
  variant?: LogoVariant;
}

/**
 * Link component that links to the EcoTrove landing page.
 * @returns a React component.
 */
export const RDCEcoTroveLogoLink: React.FC<RDCEcoTroveLogoLinkProps> = (props) => {
  const variant = props.variant ?? "base";
  const { logo, textClass } = CONFIG[variant];
  return (
    <Link className="daisy-btn daisy-btn-ghost flex w-fit flex-none items-center gap-default text-lg" to={"/"}>
      <img src={logo} height={28} width={28} alt={`${variant} EcoTrove Logo`} />
      <p className={`text-lg ${textClass}`}>EcoTrove</p>
    </Link>
  );
};
