import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";

const X = <XMarkIcon className="hero-icon w-full text-neutral-400" />;
const CHECK = <CheckIcon className="hero-icon w-full text-success" />;

// Row of "Check Savings" buttons that is displayed twice
const CHECK_SAVINGS_BUTTON_ROW = Array(3)
  .fill("")
  .map((_, i) => (
    <div key={i} className="px-default py-3">
      <a className="daisy-btn daisy-btn-primary w-full" href="/">
        Start Saving
      </a>
    </div>
  ));

/**
 * Table that displays different EcoTrove plans and their respective savings.
 * @returns a React component.
 */
export const RDCPlansTable: React.FC = () => {
  return (
    // NOTE: this is a grid rather than an HTML table because I was not able to achieve the flexibility we need for mobile with a table
    <div className="mt-8 grid grid-cols-3 divide-x divide-y divide-solid text-center lg:grid-cols-4">
      <div className="hidden lg:flex" />
      {[
        {
          header: "Free Insights",
          body: "Best for those who want to learn about their energy bills before making a commitment.",
        },
        {
          header: "Max Saver",
          body: "Our highest savings payment plan. Best for those who prioritize saving money while using more clean energy",
        },
        {
          header: "Green Saver",
          body: "Our 100% green payment plan. Ideal for individuals who want to eliminate their carbon emissions.",
        },
      ].map(({ header, body }, i) => (
        <div key={i} className="px-default py-5">
          <h3 className="font-sembold text-start">{header}</h3>
          <p className="mt-5 text-start text-neutral-content">{body}</p>
        </div>
      ))}

      <div className="hidden lg:flex" />
      {CHECK_SAVINGS_BUTTON_ROW}

      <h3 className="col-span-3 px-default py-5 text-start lg:col-span-4">
        No long-term commitment.
        <br />
        Cancel anytime.
      </h3>

      <p className="col-span-3 px-default py-3 text-start font-semibold lg:col-span-1">Annual Savings</p>
      <p className="py-3 font-semibold">$0</p>
      <p className="py-3 font-semibold">Up to 10%</p>
      <p className="py-3 font-semibold">Up to 5%</p>

      <p className="col-span-3 px-default py-3 text-start font-semibold lg:col-span-1">Change to renewable energy</p>
      <p className="py-3">{X}</p>
      <p className="py-3 font-semibold">60%+ renewable energy</p>
      <p className="py-3 font-semibold">100% renewable energy</p>

      <p className="col-span-3 px-default py-3 text-start font-semibold lg:col-span-1">Carbon emission reduction</p>
      <p className="py-3">{X}</p>
      <p className="py-3 font-semibold">35%+</p>
      <p className="py-3 font-semibold">60%+</p>

      <p className="col-span-3 px-default py-3 text-start font-semibold lg:col-span-1">
        Track monthly energy bills and compare
      </p>
      <p className="py-3">{CHECK}</p>
      <p className="py-3">{CHECK}</p>
      <p className="py-3">{CHECK}</p>

      <p className="col-span-3 px-default py-3 text-start font-semibold lg:col-span-1">
        Track monthly carbon emissions and compare
      </p>
      <p className="py-3">{CHECK}</p>
      <p className="py-3">{CHECK}</p>
      <p className="py-3">{CHECK}</p>

      <div className="hidden lg:flex" />
      {CHECK_SAVINGS_BUTTON_ROW}
    </div>
  );
};
