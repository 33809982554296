import { Utils } from "../../utils";
import { PartnerBannerProps } from "../../components";

/**
 * ID of the modal for a generic business partner banner.
 */
export const BUSINESS_PARTNER_MODAL_ID = "business-partner-modal";

/**
 * Config for a generic business partner banner.
 */
export const BUSINESS_PARTNER_BANNER_CONFIG: PartnerBannerProps = {
  name: "Business",
  banner: {
    message: "🎊 You received an exclusive invitation and discount to EcoTrove 🎉",
    hideVcLogos: true,
  },
  modal: {
    id: BUSINESS_PARTNER_MODAL_ID,
    image: {
      path: Utils.getPublicPath("images/partners/BusinessReferral.png"),
      alt: "EcoTrove's value to businesses",
    },
    header: "Exclusive Discount to EcoTrove",
    body: (
      <>
        <p>Would you stick with your landlord if they charged you random rent prices each month?</p>
        <br />
        <p>
          How about a vendor that starts 3 separate fires in your business for 4 years, and adds a 20% “extraordinary
          increase” on your rent after each of those times – would you happily pay it?
        </p>
        <br />
        <p>
          While your gut might scream “No!” to these hypothetical abusive corporate relationships, you have a real one
          in your life right now… Power bills.
        </p>
        <br />
        <p>
          At EcoTrove, our goal is simple: we want you to pay less to your utility and know exactly how much your power
          will cost. Our AI Energy Agent optimizes your online utility accounts to replace their unpredictable bills
          with a flat-price subscription to lower-cost energy. Our average customer saves 7% off their power bill, pays
          the same predictable price for power each month and uses cleaner energy.
        </p>
        <br />
        <p>
          You were referred to EcoTrove through our Partner Program. As a result, you are entitled to an exclusive
          discount. Connect your utility account with EcoTrove or schedule a call with our experts to get started and
          benefit from your discount.
        </p>
      </>
    ),
  },
};
