import { KeyIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Path, UseFormReturn } from "react-hook-form";
import { RDCValidationError } from "./RDCValidationError";
import { Utils } from "../utils";

/**
 * Form fields that are required for the `RDCConfirmPasswordsInput` component.
 */
export interface RDCConfirmPasswordsInputForm {
  password: string;
  confirmPassword: string;
}

/**
 * Props to pass to a `RDCConfirmPasswordsInput`.
 */
export interface RDCConfirmPasswordsInputProps<T extends RDCConfirmPasswordsInputForm> {
  /** Form that these inputs should be registered for. */
  form: UseFormReturn<T>;
  /** Optional string that can be applied before "Password" in each input's label. */
  labelPrefix?: string;
}

/**
 * Component that provides two password inputs, where the second one is required to match the first.
 * @param props the props to render the component with
 * @returns a React component.
 */
export function RDCConfirmPasswordsInput<T extends RDCConfirmPasswordsInputForm>(
  props: RDCConfirmPasswordsInputProps<T>,
): JSX.Element {
  const { form, labelPrefix } = props;
  const { errors } = form.formState;

  return (
    <>
      <label className="flex w-full flex-col gap-default">
        <div className="flex items-center gap-default text-neutral-content">
          <KeyIcon className="hero-icon hero-icon-sm" />
          <p>{labelPrefix ? `${Utils.capitalize(labelPrefix)} ` : ""}Password</p>
        </div>
        <input
          type="password"
          className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
          placeholder={`Your ${labelPrefix ? `${labelPrefix} ` : ""}password`}
          aria-invalid={Boolean(errors.password)}
          // The type system is not recognizing this generic for some reason, but functionally this should work and be a safe cast
          {...form.register("password" as Path<T>, {
            required: true,
            minLength: { value: 8, message: "Must be at least 8 characters" },
            maxLength: { value: 20, message: "Must be at most 20 characters" },
            validate: {
              upperCase: (value) => /(?=.*?[A-Z])/.test(value) || "Must contain at least one upper case letter",
              lowerCase: (value) => /(?=.*?[a-z])/.test(value) || "Must contain at least one lower case letter",
              digit: (value) => /(?=.*?[0-9])/.test(value) || "Must contain at least one number",
              specialChar: (value) =>
                /(?=.*?[#?!@$%^&*-])/.test(value) || "Must contain at least one special character",
            },
          })}
        />
        {/* See note above about the type system and why this cast is needed */}
        <RDCValidationError error={(errors.password?.message as string) ?? ""} />
      </label>
      {/* Confirm password */}
      <label className="flex w-full flex-col gap-default">
        <div className="flex items-center gap-default text-neutral-content">
          <KeyIcon className="hero-icon hero-icon-sm" />
          <p>
            Confirm {labelPrefix ? `${Utils.capitalize(labelPrefix)} ` : ""}
            Password
          </p>
        </div>
        <input
          type="password"
          className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
          placeholder={`Confirm your ${labelPrefix ? `${labelPrefix} ` : ""}password`}
          aria-invalid={Boolean(errors.confirmPassword)}
          // See note above about the type system and why this cast is needed
          {...form.register("confirmPassword" as Path<T>, {
            required: true,
            validate: {
              // See note above about the type system and why this cast is needed
              matches: (value) => value === form.watch("password" as Path<T>) || "Password does not match",
            },
          })}
        />
        {/* See note above about the type system and why this cast is needed */}
        <RDCValidationError error={(errors.confirmPassword?.message as string) ?? ""} />
      </label>
    </>
  );
}
