import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { CTA_LINKS, LOGIN_ROUTE, MAIN_MENU_LINKS_AUTH, MAIN_MENU_LINKS_UNAUTH } from "../constants";
import { MouseEventHandler, useEffect, useState } from "react";
import { Utils } from "../utils";
import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import { supabase } from "../supabaseClient";
import { Link as EcoLink } from "../types";
import { useAlertStore } from "../stores";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

/** Props to render a `RDCNavbar` with. */
export interface RDCNavbarProps {
  /**
   * The ID of the content that is rendered under the navbar. For pages that use snap scrolling,
   * this is needed to determine if the content has been scrolled and to style the navbar accordingly.
   */
  contentId?: string;
  /**
   * Whether to manually override calculations on the navbar and show a shadow.
   */
  displayShadow?: boolean;
}

/**
 * Navigation bar component that should be rendered on every page.
 * @returns a React `FunctionComponent`.
 */
export const RDCNavbar: React.FC<RDCNavbarProps> = (props) => {
  const { contentId, displayShadow } = props;
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [logOutLoading, setLogOutLoading] = useState(false);
  const [name, setName] = useState<string>("");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [hamburgerDropdownOpen, setHamburgerDropdownOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  // Links
  const [actionLink, setActionLink] = useState<EcoLink>();
  const { setErrorAlert } = useAlertStore();

  /* ---- EFFECTS ---- */

  // Check whether the user is logged in so that the correct auth button can be rendered
  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();

        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

        if (user) {
          setLoggedIn(true);
          const { data, error } = await supabase.from("user-profiles").select("name").eq("user_id", user.id).single();
          if (error || !data?.name) {
            throw new Error("an error occurred");
          }
          setName(Utils.formatName(data.name, "first"));
        }

        setActionLink(user ? CTA_LINKS.authenticated : CTA_LINKS.unauthenticated);
        // Add a slight delay to make the loading smoother
        setTimeout(() => setLoading(false), 100);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user data. Please try again later.");
      }
    };
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Keep track of the window's scrollY so that styling can be applied on scroll
  useEffect(() => {
    const scrollElement = contentId ? (document.getElementById(contentId) ?? window) : window;
    const handleScroll = () => setScrollY("scrollY" in scrollElement ? scrollElement.scrollY : scrollElement.scrollTop);
    scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [contentId]);

  /* ---- HANDLERS ---- */

  /**
   * Handler for logout events.
   */
  const handleLogOut: MouseEventHandler = async () => {
    try {
      setLogOutLoading(true);
      await supabase.auth.signOut();
      localStorage.clear();
      // Intentional use of window.location instead of navigate() here
      // This forces the page to refresh even if log out is clicked on the home page
      window.location.href = "/";
    } catch (error) {
      setErrorAlert("An error occurred while attempting to log out. Please try again later.");
    }
  };

  /**
   * Handler for user dropdown menu click events.
   * @param e the mouse event
   */
  const handleUserDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setUserDropdownOpen((prev) => !prev);
  };

  /**
   * Handler for hamburger dropdown menu click events.
   * @param e the mouse event
   */
  const handleHamburgerDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setHamburgerDropdownOpen((prev) => !prev);
  };

  /* ---- HELPERS ---- */

  /**
   * Helper method that creates an array of link elements based on the screen size.
   */
  const getMenuLinks = (props: { isSmallScreen: boolean }): JSX.Element[] => {
    const { isSmallScreen } = props;
    const className = isSmallScreen ? "w-full" : "";
    const showFirst = loggedIn ? MAIN_MENU_LINKS_AUTH : MAIN_MENU_LINKS_UNAUTH;
    const showSecond = loggedIn ? MAIN_MENU_LINKS_UNAUTH : MAIN_MENU_LINKS_AUTH;
    const links = Utils.getListElementsFromLinks(showFirst, { className });
    // Add additional elements to menu on smaller screens
    if (isSmallScreen) {
      if (loggedIn)
        links.push(
          ...Utils.getListElementsFromLinks(showSecond, {
            className,
            keyOffset: showFirst.length,
          }),
        );
      links.push(getAuthElement({ small: true }));
    }
    return links;
  };

  /**
   * Helper method that creates an authElement based on the screen size.
   */
  const getAuthElement = (props: { small?: boolean }): JSX.Element => {
    const { small } = props;
    const className = `daisy-btn daisy-btn-secondary ${small ? "daisy-btn-sm w-full" : ""}`;
    return loggedIn ? (
      <button key="logout-button" className={className} onClick={handleLogOut} disabled={logOutLoading}>
        Log Out
      </button>
    ) : (
      <Link key="logout-link" className={className} to={LOGIN_ROUTE}>
        Log In
      </Link>
    );
  };

  return (
    <header
      id="ecotrove-navbar"
      data-testid="ecotrove-navbar"
      className={`fixed left-0 top-0 z-40 flex w-full justify-between bg-base-100 ${
        scrollY || displayShadow ? "shadow" : ""
      } px-content-mobile py-3 xl:px-content`}
    >
      {/* Logo */}
      <span className="-ml-2">
        <RDCEcoTroveLogoLink />
      </span>
      {/* Center menu (if larger screen) */}
      <div className="absolute left-1/2 flex grow -translate-x-1/2 justify-center pt-1.5">
        {loading && <div className="daisy-skeleton hidden h-12 w-96 xl:flex" />}
        {!loading && (
          <ul className="daisy-menu daisy-menu-horizontal daisy-menu-md hidden xl:flex">
            {getMenuLinks({ isSmallScreen: false })}
          </ul>
        )}
      </div>
      {/* Action menu */}
      {loading && <div className="daisy-skeleton h-12 w-64" />}
      {!loading && (
        <div className="flex flex-none">
          {/* Action link */}
          {actionLink && (
            <Link className="text-md daisy-btn daisy-btn-primary" to={actionLink.url}>
              {"label" in actionLink ? actionLink.label : actionLink.icon}
            </Link>
          )}
          {/* Auth element (if larger screen) */}
          <span className="ml-default hidden xl:flex">
            {loggedIn ? (
              <details className="daisy-dropdown daisy-dropdown-end hidden xl:flex" open={userDropdownOpen}>
                <summary
                  className={`border-default ml-default flex cursor-pointer items-center gap-default rounded-3xl px-1.5 pr-button ${
                    userDropdownOpen ? "bg-secondary" : ""
                  } duration-200 ease-in-out hover:bg-secondary`}
                  aria-label="open user menu"
                  onClick={handleUserDropdownClick}
                >
                  <div className="daisy-mask daisy-mask-circle flex select-none place-content-center bg-neutral p-3.5">
                    {name[0].toUpperCase()}
                  </div>
                  <div className="select-none">{name}</div>
                  {userDropdownOpen ? (
                    <ChevronUpIcon className="hero-icon size-4" />
                  ) : (
                    <ChevronDownIcon className="hero-icon size-4" />
                  )}
                </summary>
                <ul className="daisy-menu daisy-dropdown-content z-[1] mt-3 w-52 items-start rounded-box bg-base-100 p-2 shadow">
                  {Utils.getListElementsFromLinks(MAIN_MENU_LINKS_UNAUTH, {
                    className: "w-full",
                    textStyle: "text-base-mobile",
                  })}
                  {getAuthElement({ small: true })}
                </ul>
              </details>
            ) : (
              getAuthElement({ small: false })
            )}
          </span>
          {/* Dropdown menu (if smaller screen) */}
          <details className="daisy-dropdown daisy-dropdown-end xl:hidden" open={hamburgerDropdownOpen}>
            <summary
              id="hamburger-swap"
              className={`daisy-btn daisy-btn-ghost daisy-swap daisy-swap-rotate -mr-2 ml-default ${
                hamburgerDropdownOpen ? "daisy-swap-active" : ""
              }`}
              aria-label="open dropdown menu"
              onClick={handleHamburgerDropdownClick}
            >
              <input type="checkbox" aria-labelledby="hamburger-swap" />
              <XMarkIcon className="hero-icon daisy-swap-on" />
              <Bars3Icon className="hero-icon daisy-swap-off" />
              {/* {hamburgerDropdownOpen ? <XMarkIcon className="hero-icon" /> : <Bars3Icon className="hero-icon" />} */}
            </summary>
            <ul className="daisy-menu daisy-dropdown-content z-[1] mt-3 w-52 items-start rounded-box bg-base-100 p-2 shadow">
              {getMenuLinks({ isSmallScreen: true })}
            </ul>
          </details>
        </div>
      )}
    </header>
  );
};
