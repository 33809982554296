import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import GlobalStyles from "../GlobalStyles"; // Import the global styles
import { supabase } from "../supabaseClient";
import aboutUsBackground from "../images/about_us_background.jpeg"; // Import the background image

const BackgroundWrapper = styled.div`
  background-image: url(${aboutUsBackground});
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the background image */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Push background behind all other content */
`;

const Container = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  z-index: 1; /* Ensure content is above the background */
`;

const Title = styled.h1`
  font-size: 26px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  padding-top: 5px;
  text-align: center; /* Center align title */
  margin: 0 0 20px 0; /* Centered margins for title */
`;

const Button = styled.button`
  padding: 0 25px;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 300px;
  background-color: #111d57;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #80aa7b;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  margin-top: 30px; /* Additional margin between button and checkboxes */

  @media (max-width: 768px) {
    width: 100%; /* Ensure button fits on mobile */
    font-size: 16px; /* Reduced font size for mobile */
  }
`;

const DoneButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 0 25px;
  border: none;
  border-radius: 10px;
  height: 50px;
  background-color: #111d57;
  color: white;
  font-size: 18px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    height: 40px;
    padding: 0 15px;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 22px;
  color: #111d57;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Center align content */
  text-align: center; /* Center align text */
  width: 85%; /* Slightly wider checkbox label */

  @media (max-width: 768px) {
    font-size: 16px; /* Reduced font size for mobile */
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  width: 20px; /* Larger checkbox */
  height: 20px; /* Larger checkbox */
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: center; /* Center align error messages */
`;

const HyperlinkButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 16px; /* Reduced font size for mobile */
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(230, 247, 255, 0.95); /* Slight transparency */
  z-index: 10;
`;

const ModalContent = styled.div`
  width: 95%;
  height: calc(90% - 25px);
  padding: 17px 2.5% 10px;
  background-color: white;
  position: relative;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const PrivacyPolicyModal = ({
  onClose,
  policyUrl,
  title,
}: {
  onClose: () => void;
  policyUrl: string;
  title: string;
}) => {
  const [iframeBlocked, setIframeBlocked] = useState(false);

  const handleIframeLoadError = () => {
    setIframeBlocked(true);
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains("modal")) {
      onClose();
    }
  };

  return (
    <ModalContainer className="modal" onClick={handleClickOutside}>
      <ModalContent>
        <h2>{title}</h2>
        {iframeBlocked ? (
          <div>
            <p>
              Your browser does not allow pop-ups, we are redirecting you to the policy hosted on our website for your
              review. You will be redirected back to this page when you reach the end of our policy.
            </p>
            <a href={policyUrl} target="_blank" rel="noopener noreferrer">
              Click here to view the Policy.
            </a>
          </div>
        ) : (
          <Iframe src={policyUrl} title={title} onError={handleIframeLoadError}></Iframe>
        )}
        <DoneButton onClick={onClose}>Done</DoneButton>
      </ModalContent>
    </ModalContainer>
  );
};

// Define the type for the `onComplete` prop
interface TermsAndConditionsProps {
  onComplete?: () => void; // The onComplete function is optional
  email?: string; // The email prop is optional
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ email, onComplete }) => {
  const navigate = useNavigate();
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [privacyViewed, setPrivacyViewed] = useState(false);
  const [termsViewed, setTermsViewed] = useState(false);
  const [modalOpen, setModalOpen] = useState<"privacy" | "terms" | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const navigateNext = async () => {
    let userEmail = email;
    if (userEmail === "" || userEmail === undefined || userEmail === null) {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null && user !== undefined && user) {
        const { data: userProfile, error: userProfileError } = await supabase
          .from("user-profiles")
          .select("*")
          .eq("user_id", user?.id)
          .single();

        if (userProfileError) {
          console.error("User profile data was not fetched in Terms and Conditions");
        }
        console.log(userProfile);
        userEmail = userProfile?.email || "";
      }
    }

    if (!isPrivacyChecked || !isTermsChecked) {
      setErrorMessage("Please review and accept EcoTrove's privacy policy and terms of service to continue");
      return;
    }

    const { data, error } = await supabase //right now we assume user is already logged in and this is in login page
      .from("terms-and-conditions")
      .insert([{ email: userEmail, privacy_checked: true, terms_checked: true }])
      .select();

    const { data: completedTerms, error: errorTerms } = await supabase //right now we assume user is already logged in and this is in login page
      .from("user-profiles")
      .update({ terms_complete: true })
      .eq("email", userEmail);

    console.log(data, completedTerms);

    if (error || errorTerms) {
      console.error("error uploading row for terms and conditions completion for: ", email);
    }

    console.log("on complete: ", onComplete);

    // If `onComplete` is provided, call it (used in PGEOauthredirect to close the iframe)
    if (onComplete && onComplete !== undefined && onComplete !== null) {
      onComplete();
    } else {
      // Otherwise, navigate to the user dashboard (used in login flow)
      navigate("/user-dashboard");
    }
  };

  const handlePolicyModalOpen = (type: "privacy" | "terms") => {
    setModalOpen(type);
    setErrorMessage("");
  };

  const handlePolicyModalClose = (type: "privacy" | "terms") => {
    setModalOpen(null);
    if (type === "privacy") {
      setPrivacyViewed(true);
    } else {
      setTermsViewed(true);
    }
  };

  return (
    <>
      <GlobalStyles /> {/* Include global styles */}
      <BackgroundWrapper /> {/* Background color that covers the entire screen */}
      <main>
        <Container>
          <Title>Please review and accept our terms before proceeding:</Title>

          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={isPrivacyChecked}
              onChange={() => {
                if (privacyViewed) {
                  setIsPrivacyChecked(!isPrivacyChecked);
                }
              }}
              disabled={!privacyViewed}
            />
            I have reviewed and I agree to EcoTrove's privacy policy.
            <HyperlinkButton onClick={() => handlePolicyModalOpen("privacy")}>View Privacy Policy</HyperlinkButton>
          </CheckboxLabel>

          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={isTermsChecked}
              onChange={() => {
                if (termsViewed) {
                  setIsTermsChecked(!isTermsChecked);
                }
              }}
              disabled={!termsViewed}
            />
            I have reviewed and I agree to EcoTrove's terms of service.
            <HyperlinkButton onClick={() => handlePolicyModalOpen("terms")}>View Terms of Service</HyperlinkButton>
          </CheckboxLabel>

          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          <Button onClick={navigateNext} disabled={!isPrivacyChecked || !isTermsChecked}>
            Continue
          </Button>
        </Container>
      </main>
      {modalOpen === "privacy" && (
        <PrivacyPolicyModal
          onClose={() => handlePolicyModalClose("privacy")}
          policyUrl="https://ecotrove.com/privacy-policy"
          title="EcoTrove Privacy Policy"
        />
      )}
      {modalOpen === "terms" && (
        <PrivacyPolicyModal
          onClose={() => handlePolicyModalClose("terms")}
          policyUrl="https://ecotrove.com/terms-of-service"
          title="EcoTrove Terms of Service"
        />
      )}
    </>
  );
};

export default TermsAndConditions;
