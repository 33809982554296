import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import {
  CTA_LINKS,
  ECOTROVE_EMAIL_INFO,
  ECOTROVE_FACEBOOK_URL,
  ECOTROVE_INSTAGRAM_URL,
  ECOTROVE_LINKEDIN_URL,
  ECOTROVE_TIKTOK_URL,
  ECOTROVE_TWITTER_URL,
  MAIN_MENU_LINKS_AUTH,
  MAIN_MENU_LINKS_UNAUTH,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from "../constants";
import { Utils } from "../utils";
import { Link } from "../types";
import FacebookLogo from "../images/logos/FacebookLogo.svg";
import InstagramLogo from "../images/logos/InstagramLogo.svg";
import TwitterLogo from "../images/logos/TwitterLogo.svg";
import LinkedInLogo from "../images/logos/LinkedInLogo.svg";
import TikTokLogo from "../images/logos/TikTokLogo.svg";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";

const FOOTER_LINKS: Link[] = [
  { label: "Privacy Policy", url: PRIVACY_POLICY_ROUTE },
  { label: "Terms of Service", url: TERMS_OF_SERVICE_ROUTE },
  // TODO: cookies settings
];

const SOCIAL_MEDIA_LINKS: Link[] = [
  {
    icon: <EnvelopeIcon className="hero-icon hero-icon-sm" />,
    ariaLabel: "email",
    url: `mailto:${ECOTROVE_EMAIL_INFO}`,
  },
  {
    icon: <img src={FacebookLogo} alt="Facebook" />,
    url: ECOTROVE_FACEBOOK_URL,
  },
  {
    icon: <img src={InstagramLogo} alt="Instagram" />,
    url: ECOTROVE_INSTAGRAM_URL,
  },
  {
    icon: <img src={TwitterLogo} alt="Twitter/X" />,
    url: ECOTROVE_TWITTER_URL,
  },
  {
    icon: <img src={LinkedInLogo} alt="LinkedIn" />,
    url: ECOTROVE_LINKEDIN_URL,
  },
  {
    icon: <img src={TikTokLogo} alt="TikTok" />,
    url: ECOTROVE_TIKTOK_URL,
  },
];

/**
 * Props to pass to a `RDCFooter`.
 */
export interface RDCFooterProps {
  showExplainer?: boolean;
}

/**
 * Footer component that should be rendered on every page.
 * @returns a React component.
 */
export const RDCFooter: React.FC<RDCFooterProps> = (props) => {
  const { showExplainer } = props;
  const [isSignedIn, setIsSignedIn] = useState(false);

  // Check whether the user is logged in so that the correct auth button can be rendered
  useEffect(() => {
    const checkLoggedIn = async () => {
      // Comment this out for local testing
      const {
        data: { user },
      } = await supabase.auth.getUser();

      // Uncomment this for local testing
      // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

      setIsSignedIn(Boolean(user));
    };
    checkLoggedIn();
  }, []);
  return (
    <footer className="flex w-full flex-col items-center gap-8 p-content-mobile lg:p-content">
      {showExplainer && (
        <>
          <p className="w-full">
            * The average EcoTrove customers saves 7% off their power bills which is around ~$327 for our household
            customers and ~$2,475 per location for our business customers.
          </p>
          <hr className="w-full" />
        </>
      )}
      <div className="flex w-full flex-col items-center justify-between gap-4 lg:flex-row">
        {/* Logo */}
        <span className="-ml-2">
          <RDCEcoTroveLogoLink />
        </span>
        {/* Center menu */}
        <div className="flex justify-center">
          <ul className="daisy-menu daisy-menu-md lg:daisy-menu-horizontal">
            {Utils.getListElementsFromLinks(
              [
                ...(isSignedIn ? MAIN_MENU_LINKS_AUTH : MAIN_MENU_LINKS_UNAUTH),
                isSignedIn ? CTA_LINKS.authenticated : CTA_LINKS.unauthenticated,
                // Intentional use of '!=' to check for null and undefined
                // eslint-disable-next-line eqeqeq
              ].filter((val) => val != undefined) as Link[],
            )}
          </ul>
        </div>
        {/* Social media */}
        <div className="-mr-2 flex flex-none justify-center">
          <ul className="flex items-center">
            {Utils.getListElementsFromLinks(SOCIAL_MEDIA_LINKS, {
              className: "daisy-btn daisy-btn-ghost daisy-btn-circle",
              newTab: true,
            })}
          </ul>
        </div>
      </div>
      <hr className="w-full" />
      <div className="flex w-full flex-col text-3xl lg:flex-row lg:text-sm">
        {/* Left corner (top item on mobile screens) */}
        <div className="flex flex-col items-center lg:items-start">
          <a className="flex items-center gap-x-default" href={`mailto:${ECOTROVE_EMAIL_INFO}`}>
            <EnvelopeIcon className="hero-icon hero-icon-xs" />
            <p className="daisy-link-hover daisy-link text-sm">{ECOTROVE_EMAIL_INFO}</p>
          </a>
          <div className="flex items-center gap-x-default">
            <p className="text-sm">&copy;</p>
            <p className="text-sm">{new Date().getUTCFullYear()} EcoTrove. All rights reserved.</p>
          </div>
        </div>
        <div className="grow" />
        {/* Right corner (bottom item on mobile screens) */}
        <ul className="-mr-2 mt-4 flex flex-col items-center gap-x-4 lg:-mb-5 lg:mt-0 lg:flex-row">
          {Utils.getListElementsFromLinks(FOOTER_LINKS, {
            className: "daisy-btn daisy-btn-sm daisy-btn-ghost",
          })}
        </ul>
      </div>
    </footer>
  );
};
