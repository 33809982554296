export class GoogleAdsService {
  /**
   * Log an event for the provided conversion ID.
   *
   * NOTE: While `react-ga4` is used to initialize the `window.gtag` call below, that library's built-in
   * `event()` doesn't seem to work for tracking multiple Google Ad conversion IDs. This is a custom solution that fixes this.
   *
   * If a method is discovered to do this in `react-ga4`, feel free to delete this.
   * @param conversionId the Google conversion ID to log the event for.
   */
  static event(conversionId: string): void {
    if (!conversionId) {
      throw new Error("must provide a non-empty conversionId");
    }
    if (typeof window !== "undefined" && "gtag" in window && typeof window.gtag === "function") {
      window.gtag("event", "conversion", { send_to: conversionId });
    }
  }
}
