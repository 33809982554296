import React, { PropsWithChildren } from "react";
import { Modal } from "..";
import { Utils } from "../../utils";

/**
 * Props to pass to a `ModalLink`.
 */
export interface ModalLinkProps {
  /** The ID of the modal that will display. */
  modalId: string;
  /** The href to display in the modal. */
  href: string;
  /** Class to optionally style the link with. */
  className?: string;
}

/**
 * A link component that displays the linked page in a modal.
 */
export const ModalLink: React.FC<ModalLinkProps & PropsWithChildren> = ({ modalId, href, className, children }) => {
  const url = new URL(href, window.location.origin);
  url.searchParams.set("iframe", "true");
  return (
    <>
      <span
        role="button"
        className={`daisy-link ${className}`}
        onClick={(e) => {
          e.preventDefault();
          Utils.openDialogModal(modalId);
        }}
      >
        {children}
      </span>
      <Modal id={modalId} className="h-full w-full">
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe className="h-full w-full" src={url.href} />
      </Modal>
    </>
  );
};
