import { RDCFooter, RDCNavbar, RDCValidationError } from "../components";
import GiftIcon from "../images/icons/GiftIcon.svg";
import InviteAFriendGraphic from "../images/graphics/InviteAFriendGraphic.png";
import FriendJoinGraphic from "../images/graphics/FriendJoinGraphic.png";
import ReferralDiscountGraphic from "../images/graphics/ReferralDiscountGraphic.png";
import {
  ECOTROVE_URL,
  EMAIL_REGEX,
  LOGIN_ROUTE,
  REFER_ROUTE,
  REFERRAL_DISCOUNT,
  SUPABASE_UNAUTHENTICATED_ERROR_CODE,
} from "../constants";
import { ChatBubbleOvalLeftEllipsisIcon, EnvelopeIcon, LinkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { SubmitHandler, useForm } from "react-hook-form";
import WhatsappLogoPrimary from "../images/icons/whatsappIconPrimary.svg";
import { FiInstagram } from "react-icons/fi";
import { Utils } from "../utils";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";

const PAGE_ID = "refer-page";
const EMAIL_MODAL_ID = "email-modal";

/**
 * Dotted line that is used to separate referral steps on large screens.
 */
const DOTTED_LINE = (
  <div className="hidden flex-col lg:flex lg:grow">
    <div className="h-1/4 border-b-2 border-dashed" />
  </div>
);

/**
 * Helper method to format referral steps as cards.
 * @param props the props to render the card with
 * @returns a JSX element that represents a card.
 */
const formatAsCard = (props: { img: string; alt: string; i: number; header: string; body: string }): JSX.Element => {
  const { img, alt, i, header, body } = props;
  return (
    <div className="flex flex-none flex-col items-center gap-default pt-4 max-md:snap-start lg:w-1/4 lg:items-start">
      <img src={img} alt={alt} />
      <div className="flex items-center gap-default">
        <div className="daisy-mask daisy-mask-circle flex place-content-center bg-accent p-3 text-lg">{i}</div>
        <h3 className="text-lg">{header}</h3>
      </div>
      <p className="text-center text-neutral-content">{body}</p>
    </div>
  );
};

/**
 * Page that provides a referral link for customers.
 * @returns a React component.
 */
export const RDCReferPage: React.FC = () => {
  const navigate = useNavigate();
  const { setSuccessAlert, setErrorAlert } = useAlertStore();
  const [email, setEmail] = useState<string>("");
  const [referralLink, setReferralLink] = useState<string>("");
  const [referralMessage, setReferralMessage] = useState<string>("");
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const emailForm = useForm<{ email: string }>();

  useEffect(() => {
    mixpanel.track("refer");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure user is logged in
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user?.id) {
          navigate(LOGIN_ROUTE, { replace: true });
          return;
        }

        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

        const { data, error } = await supabase.from("user-profiles").select("email").eq("user_id", user.id).single();
        if (error || !data?.email) {
          throw new Error("an error occurred");
        }
        setEmail(data.email);
        setReferralLink(`${ECOTROVE_URL}${REFER_ROUTE}/${data.email}`);
        setReferralMessage(
          `Use my EcoTrove referral link to get $${REFERRAL_DISCOUNT} off your next power bill! ${referralLink}`,
        );
        setTimeout(() => setLoading(false), 100);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user data. Please try again later.");
      }
    };
    fetchData();
  }, [navigate, referralLink, setErrorAlert]);

  /**
   * Handler for copy link events.
   */
  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  /**
   * Handler for email share events.
   */
  const handleShareEmail: SubmitHandler<{ email: string }> = async (data) => {
    try {
      const { error } = await supabase.from("referral-campaign").insert({
        referrer_email: email,
        referred_email: data.email,
        referrer_link: referralLink,
      });
      if (error) throw error;
      setSuccessAlert(`Your referral link has been sent to ${data.email}.`);
    } catch (error) {
      if (error && typeof error === "object" && "code" in error && error.code === SUPABASE_UNAUTHENTICATED_ERROR_CODE) {
        setErrorAlert("You must be logged in to use this feature.");
      } else {
        setErrorAlert("An error occurred while sending referral link. Please try again later.");
      }
    } finally {
      Utils.closeDialogModal(EMAIL_MODAL_ID);
    }
  };

  return (
    <div id={PAGE_ID} className="snap-scroll-page">
      <RDCNavbar contentId={PAGE_ID} />

      <div className="px-content-mobile lg:px-content">
        {/* Refer a friend section */}
        <div className="snap-start py-8">
          <div className="flex flex-col items-center gap-4 rounded-3xl bg-secondary p-8 text-center">
            <img src={GiftIcon} alt="gift" />
            <h1 className="text-xl">
              Refer a Friend and
              <br />
              Get ${REFERRAL_DISCOUNT} off Your Next Invoice
            </h1>
            <p>
              EcoTrove is better with friends. You and your friend will both earn ${REFERRAL_DISCOUNT} for each new
              referral.
            </p>
            {loading ? (
              <div className="daisy-skeleton h-36 w-full lg:w-2/3" />
            ) : (
              <>
                <div className="flex w-full flex-col items-center justify-center gap-default lg:flex-row">
                  <div className="flex w-full max-w-full justify-center gap-default rounded-3xl bg-primary-content px-button py-2.5 first-letter:items-center lg:w-fit">
                    <LinkIcon className="hero-icon hero-icon-sm" />
                    <p className="overflow-hidden text-ellipsis">{referralLink}</p>
                  </div>
                  <div
                    className="daisy-tooltip daisy-tooltip-bottom daisy-tooltip-open daisy-tooltip-accent w-full lg:w-fit"
                    data-tip={tooltipOpen ? "Copied!" : undefined}
                  >
                    <button className="daisy-btn daisy-btn-primary w-full" onClick={handleCopyLink}>
                      Copy Link
                    </button>
                  </div>
                </div>
                <p>Or share your link via social media</p>
                <div className="-mt-4 flex w-full items-center justify-center">
                  {/* Share links */}
                  {[
                    {
                      icon: <EnvelopeIcon className="hero-icon hero-icon-sm text-primary" aria-label="email" />,
                      handleClick: () => {
                        Utils.openDialogModal(EMAIL_MODAL_ID);
                        emailForm.reset();
                      },
                    },
                    {
                      icon: (
                        <ChatBubbleOvalLeftEllipsisIcon
                          className="hero-icon hero-icon-sm text-primary"
                          aria-label="SMS"
                        />
                      ),
                      handleClick: () => window.open(`sms:?body=${encodeURIComponent(referralMessage)}`),
                    },
                    {
                      icon: <FiInstagram className="hero-icon hero-icon-sm text-primary" aria-label="instagram" />,
                      handleClick: () => {
                        navigator.clipboard.writeText(referralMessage);
                        window.open(`https://www.instagram.com/direct/inbox`, "_blank");
                        setSuccessAlert("Referral link copied to clipboard!");
                      },
                    },
                    {
                      icon: <img src={WhatsappLogoPrimary} alt="Whatsapp" />,
                      handleClick: () => window.open(`https://wa.me/?text=${encodeURIComponent(referralMessage)}`),
                    },
                  ].map(({ icon, handleClick }, i) => (
                    <div
                      key={i}
                      role="button"
                      className="daisy-btn daisy-btn-circle daisy-btn-ghost"
                      onClick={handleClick}
                    >
                      {icon}
                    </div>
                  ))}
                </div>
                <dialog id={EMAIL_MODAL_ID} className="daisy-modal p-content-mobile lg:p-content">
                  <div className="daisy-modal-box flex flex-col p-6">
                    <form
                      className="flex w-full flex-col items-start gap-default"
                      onSubmit={emailForm.handleSubmit((data) =>
                        handleShareEmail({
                          ...data,
                          email: data.email.toLowerCase(),
                        }),
                      )}
                    >
                      <p id="email-label">Enter an email to share with</p>
                      <div className="flex w-full gap-default">
                        <input
                          className="daisy-input daisy-input-bordered grow aria-invalid:daisy-input-error aria-invalid:text-error"
                          type="text"
                          placeholder="Enter an email address"
                          aria-labelledby="email-label"
                          aria-invalid={Boolean(emailForm.formState.errors.email)}
                          {...emailForm.register("email", {
                            required: true,
                            validate: {
                              matches: (value) => EMAIL_REGEX.test(value) || "Please enter a valid email",
                            },
                          })}
                        />
                      </div>
                      <RDCValidationError error={emailForm.formState.errors.email?.message ?? ""} />

                      <div className="daisy-modal-action self-end">
                        <button className="daisy-btn daisy-btn-primary" type="submit">
                          Submit
                        </button>
                        <button
                          className="daisy-btn daisy-btn-neutral"
                          onClick={() => Utils.closeDialogModal(EMAIL_MODAL_ID)}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </dialog>
              </>
            )}
          </div>
        </div>

        {/* Earn reward section */}
        <div className="flex snap-start flex-col items-center gap-4 py-8">
          <h2 className="text-xl">How to Earn a Reward?</h2>
          <div className="flex flex-col gap-16 text-center lg:w-3/4 lg:flex-row lg:gap-0 lg:text-start">
            {formatAsCard({
              img: InviteAFriendGraphic,
              alt: "invite a friend via text message",
              i: 1,
              header: "Invite a Friend",
              body: "Invite your friend to try EcoTrove over text, email, or WhatsApp.",
            })}
            {DOTTED_LINE}
            {formatAsCard({
              img: FriendJoinGraphic,
              alt: "friend sets up EcoTrove account",
              i: 2,
              header: "They join EcoTrove",
              body: "Your friend gets a custom quote and joins an EcoTrove paid plan.",
            })}
            {DOTTED_LINE}
            {formatAsCard({
              img: ReferralDiscountGraphic,
              alt: "discount price tag",
              i: 3,
              header: `You both get $${REFERRAL_DISCOUNT} off`,
              body: `Both you and your friend get $${REFERRAL_DISCOUNT} off your next monthly bill. No limits!`,
            })}
          </div>
        </div>
      </div>
      <div className="snap-start">
        <RDCFooter />
      </div>
    </div>
  );
};
