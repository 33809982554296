import { COMODOSSL_URL, DATAGUARD_URL, PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE } from "../../constants";
import { Utils } from "../../utils";
import { ModalLink } from "../ModalLink";

/**
 * Props to pass to an `AuthorizationDisclosure`.
 */
export interface AuthorizationDisclosureProps {
  /** Optional Share My Data URL to display in disclaimer (if applicable). */
  shareMyDataUrl?: string;
}

/**
 * Disclosure text + icons to be displayed before authorization.
 * @returns a React component
 */
export const AuthorizationDisclosure: React.FC<AuthorizationDisclosureProps> = ({ shareMyDataUrl }) => {
  return (
    <>
      <p data-testid="disclosure-text">
        By clicking <b>Authorize</b>, I authorize EcoTrove to login to my accounts
        {shareMyDataUrl ? (
          <span>
            , authorize{" "}
            <a className="daisy-link" href={shareMyDataUrl} rel="noopener noreferrer" target="_blank">
              ShareMyData
            </a>{" "}
            on my behalf,{" "}
          </span>
        ) : (
          " "
        )}
        and I agree to EcoTrove's{" "}
        <ModalLink modalId="tos-modal" href={TERMS_OF_SERVICE_ROUTE}>
          Terms of Service
        </ModalLink>{" "}
        and{" "}
        <ModalLink modalId="pp-modal" href={PRIVACY_POLICY_ROUTE}>
          Privacy Policy
        </ModalLink>
        .
      </p>
      {/* Security logos */}
      <div className="flex w-full items-center justify-evenly">
        {[
          { path: "images/logos/DataGuardLogo.png", alt: "DataGuard energy data privacy program", href: DATAGUARD_URL },
          { path: "images/logos/ComodoLogo.png", alt: "Comodo secure", href: COMODOSSL_URL },
        ].map(({ path, alt, href }, i) => (
          <a key={i} href={href} rel="noopener noreferrer" target="_blank">
            <img className="h-16" src={Utils.getPublicPath(path)} alt={alt} />
          </a>
        ))}
      </div>
    </>
  );
};
