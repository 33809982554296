import { z } from "zod";
import { Utils } from "../../utils";

/**
 * Zod schema for validationg a utility provider.
 */
export const UTILITY_PROVIDER_SCHEMA = z.enum([
  // IMPORTANT: When adding/modifying a value here, make sure to update the Webflow dropdown as well with the correct name and value.
  "cityOfPaloAlto",
  "cityOfRoseville",
  "cityOfSantaClara",
  "conEdison",
  "floridaPowerAndLight",
  "ladwp",
  "nationalGridUpstateNY",
  "nationalGridDownstateNY",
  "nationalGridMA",
  "pge",
  "pugetSoundEnergy",
  "sce",
  "sdge",
  "smud",
  "soCalGas",
]);

/**
 * String union type that represents utility providers.
 */
export type UtilityProvider = z.infer<typeof UTILITY_PROVIDER_SCHEMA>;

/**
 * Information about a utility provider.
 */
export type UtilityProps = {
  provider: UtilityProvider;
  fullName: string;
  abbreviatedName: string;
  logoPath: string;
  resetUsernameUrl?: string;
  resetPasswordUrl?: string;
  shareMyDataUrl?: string;
};

/**
 * Config for all utility providers.
 * NOTE: this is the order utilities will be displayed in; try to maintain alphabetical order.
 */
export const UTILITIES_CONFIG: Record<UtilityProvider, UtilityProps> = {
  // ======== CITY OF PALO ALTO UTILITIES =============================================================================
  cityOfPaloAlto: {
    provider: "cityOfPaloAlto",
    fullName: "City of Palo Alto Utilities",
    abbreviatedName: "City of Palo Alto Utilities",
    logoPath: Utils.getPublicPath("images/logos/CityOfPaloAltoLogo.png"),
    resetUsernameUrl: "https://mycpau.cityofpaloalto.org/portal/LoginSupport.aspx?id=2",
    resetPasswordUrl: "https://mycpau.cityofpaloalto.org/portal/LoginSupport.aspx?id=1",
  },
  // ======== CITY OF ROSEVILLE UTILITIES =============================================================================
  cityOfRoseville: {
    provider: "cityOfRoseville",
    fullName: "City of Roseville Utilities",
    abbreviatedName: "City of Roseville Utilities",
    logoPath: Utils.getPublicPath("images/logos/CityOfRosevilleLogo.png"),
    // resetUsernameUrl: "", no URL on their site
    resetPasswordUrl: "https://css.roseville.ca.us/billpay/public/forgot",
  },
  // ======== CITY OF SANTA CLARA =====================================================================================
  cityOfSantaClara: {
    provider: "cityOfSantaClara",
    fullName: "City of Santa Clara Utilities (Silicon Valley Power)",
    abbreviatedName: "City of Santa Clara Utilities",
    logoPath: Utils.getPublicPath("images/logos/CityOfSantaClaraLogo.png"),
    resetUsernameUrl: "https://mua.santaclaraca.gov/CC/Login.xml",
    resetPasswordUrl: "https://mua.santaclaraca.gov/CC/Login.xml",
  },
  // ======== CON EDISON ==============================================================================================
  conEdison: {
    provider: "conEdison",
    fullName: "Con Edison",
    abbreviatedName: "Con Edison",
    logoPath: Utils.getPublicPath("images/logos/ConEdisonLogo.png"),
    // resetUsernameUrl: "" no URL on their site
    resetPasswordUrl: "https://www.coned.com/en/register/reset-password",
  },
  // ======== FPL =====================================================================================================
  floridaPowerAndLight: {
    provider: "floridaPowerAndLight",
    fullName: "FPL (Florida Power and Light)",
    abbreviatedName: "FPL",
    logoPath: Utils.getPublicPath("images/logos/FloridaPowerAndLightLogo.png"),
    resetUsernameUrl: "https://www.fpl.com/my-account/registration.html?cid=aliasforgotid#forgot-userid",
    resetPasswordUrl: "https://www.fpl.com/my-account/forgot-password.html?cid=aliasforgotpass#forgotPassStep1",
  },
  // ======== LADWP ===================================================================================================
  ladwp: {
    provider: "ladwp",
    fullName: "LADWP (Los Angeles Department of Water & Power)",
    abbreviatedName: "LADWP",
    logoPath: Utils.getPublicPath("images/logos/LADWPLogo.png"),
    resetUsernameUrl: "https://myaccount.ladwp.com/ladwp/faces/internal/ForgotPassword", // This link has a tab for username as well
    resetPasswordUrl: "https://myaccount.ladwp.com/ladwp/faces/internal/ForgotPassword",
  },
  // ======== NATIONAL GRID (MA) ============================================================================
  nationalGridMA: {
    provider: "nationalGridMA",
    fullName: "National Grid (Massachusetts)",
    abbreviatedName: "National Grid (Massachusetts)",
    logoPath: Utils.getPublicPath("images/logos/NationalGridLogo.png"),
    // Reset email/password links involve specific state strings
  },
  // ======== NATIONAL GRID (DOWNSTATE NY) ============================================================================
  nationalGridDownstateNY: {
    provider: "nationalGridDownstateNY",
    fullName: "National Grid (Downstate New York)",
    abbreviatedName: "National Grid (Downstate New York)",
    logoPath: Utils.getPublicPath("images/logos/NationalGridLogo.png"),
    // Reset email/password links involve specific state strings
  },
  // ======== NATIONAL GRID (UPSTATE NY) ==============================================================================
  nationalGridUpstateNY: {
    provider: "nationalGridUpstateNY",
    fullName: "National Grid (Upstate New York)",
    abbreviatedName: "National Grid (Upstate New York)",
    logoPath: Utils.getPublicPath("images/logos/NationalGridLogo.png"),
    // Reset email/password links involve specific state strings
  },
  // ======== PG&E ====================================================================================================
  pge: {
    provider: "pge",
    fullName: "PG&E (Pacific Gas and Electric)",
    abbreviatedName: "PG&E",
    logoPath: Utils.getPublicPath("images/logos/PGELogo.svg"),
    resetUsernameUrl: "https://www.pge.com/eimp/#forgotUserName",
    resetPasswordUrl: "https://www.pge.com/eimp/#forgotPassword",
    shareMyDataUrl: "https://www.pge.com/sharemydata/",
  },
  // ======== PSE =====================================================================================================
  pugetSoundEnergy: {
    provider: "pugetSoundEnergy",
    fullName: "PSE (Puget Sound Energy)",
    abbreviatedName: "PSE",
    logoPath: Utils.getPublicPath("images/logos/PugetSoundEnergyLogo.png"),
    resetUsernameUrl: "https://www.pse.com/en/forgot-username",
    resetPasswordUrl: "https://www.pse.com/en/forgot-password",
  },
  // ======== SCE =====================================================================================================
  sce: {
    provider: "sce",
    fullName: "SCE (Southern California Edison)",
    abbreviatedName: "SCE",
    logoPath: Utils.getPublicPath("images/logos/SCELogo.svg"),
    // resetUsernameUrl: "" no URL on their site
    resetPasswordUrl: "https://www.sce.com/mysce/resetpassword",
  },
  // ======== SDG&E ===================================================================================================
  sdge: {
    provider: "sdge",
    fullName: "SDG&E (San Diego Gas and Electric)",
    abbreviatedName: "SDG&E",
    logoPath: Utils.getPublicPath("images/logos/SDGELogo.svg"),
    resetUsernameUrl: "https://myenergycenter.com/portal/ForgotUsername",
    resetPasswordUrl: "https://myenergycenter.com/portal/ForgotPassword",
  },
  // ======== SMUD ====================================================================================================
  smud: {
    provider: "smud",
    fullName: "SMUD (Sacramento Municipal Utility District)",
    abbreviatedName: "SMUD",
    logoPath: Utils.getPublicPath("images/logos/SMUDLogo.png"),
    resetUsernameUrl: "https://myaccount.smud.org/recoveruser",
    resetPasswordUrl: "https://myaccount.smud.org/recover/password",
  },
  // ======== SOCAL GAS ===============================================================================================
  soCalGas: {
    provider: "soCalGas",
    fullName: "SoCalGas",
    abbreviatedName: "SoCalGas",
    logoPath: Utils.getPublicPath("images/logos/SoCalGasLogo.png"),
    resetUsernameUrl: "https://myaccount.socalgas.com/ui/forgot-password",
    resetPasswordUrl: "https://myaccount.socalgas.com/ui/forgot-password",
  },
};
