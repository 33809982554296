import ReactECharts from "echarts-for-react";
import { EChartsOption, registerTheme } from "echarts";
import { useEffect } from "react";
import {
  ECHARTS_BAR_STYLE,
  ECHARTS_NEUTRAL,
  ECHARTS_NEUTRAL_CONTENT,
  ECHARTS_PRIMARY,
  ECHARTS_SECONDARY,
  ECHARTS_THEME,
} from "../constants";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { MonthData, Utils } from "../utils";

interface Usage {
  month: number;
  year: number;
  usage: number;
}

interface ChartData {
  label: string;
  value: number | undefined;
}

const DATASET_NAMES = {
  before: "Before EcoTrove",
  after: "After EcoTrove",
  estimate: "EcoTrove Estimate",
};

const LABEL_BY_TYPE: Record<Type, string> = {
  gas: "thm",
  electricity: "kWh",
};

const TEXT_OPTIONS = {
  show: true,
  fontSize: 12,
  fontFamily: "Gilroy-Medium",
  color: ECHARTS_NEUTRAL_CONTENT,
};

export type Type = "gas" | "electricity";

export interface ServiceData {
  historicalUsage: Usage[];
  estimatedUsage: Usage[];
}

/**
 * Props to pass to a `RDCUsageGraph`.
 */
export interface RDCUsageGraphProps {
  modalId: string;
  type: Type;
  data: ServiceData;
  startDate?: Date;
}

/**
 * Graph that displays a user's historical usage for the provided service account type.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCUsageGraph: React.FC<RDCUsageGraphProps> = (props) => {
  const { modalId, type, data, startDate } = props;

  useEffect(() => {
    // Register ECharts theme
    registerTheme("main", ECHARTS_THEME);
  }, []);

  /**
   * Helper method to get data about the specified month.
   * @param options optionally, the number of months to offset by from the current month
   * @returns the month data.
   */
  const getUTCMonthData = (props?: { date?: Date; offset?: number }): MonthData => {
    const date = props?.date ?? new Date();
    const month = new Date(date.getUTCFullYear(), date.getUTCMonth() + (props?.offset ?? 0));
    return { monthNum: month.getUTCMonth(), yearNum: month.getUTCFullYear() };
  };

  /**
   * Helper method that gets data about the previous n months.
   * @param the props to retrieve the data with
   * @returns an array of months data.
   */
  const getMonths = (props: { n: number; inclusive?: boolean }): MonthData[] => {
    const { n, inclusive } = props;
    const months: MonthData[] = [];
    const offset = inclusive ? 1 : 0;
    for (let i = n; i > 0; i--) {
      months.push(getUTCMonthData({ offset: -(i - offset) }));
    }
    return months;
  };

  /**
   * Helper method that formats a month into a readable string.
   * @param month the month to format.
   * @returns the formatted month string.
   */
  const formatMonth = (month: Date | MonthData): string => {
    const date = month instanceof Date ? month : new Date(`${month.monthNum + 1}/1/${month.yearNum}`);
    return `${date.toLocaleString("default", { month: "short" })} '${date.toLocaleDateString("default", {
      year: "2-digit",
    })}`;
  };

  /**
   * Helper method that checks whether the provided month is prior to the start date.
   * @param month the month to compare
   * @returns whether or not `month` is prior to the start date.
   */
  const isPriorToStartDate = (month: MonthData): boolean => {
    const start = startDate ? getUTCMonthData({ date: startDate }) : undefined;
    return Boolean(
      start && (month.yearNum < start.yearNum || (month.yearNum === start.yearNum && month.monthNum < start.monthNum)),
    );
  };

  /**
   * Helper method that gets the chart config.
   * @returns an `EChartsOption` object.
   */
  const getChartOption = (numMonths: number): EChartsOption => {
    const monthData = getMonths({ n: numMonths, inclusive: true });
    const priorData: ChartData[] = [];
    const afterData: ChartData[] = [];
    const estimateData: ChartData[] = [];
    let startData: ChartData | undefined;

    monthData.forEach((month) => {
      // First, get the actual usage value for the month (and separate it based on whether it is before or after the start date)
      const monthLabel = formatMonth(month);
      const monthValue =
        data.historicalUsage.find((usage) => usage.month === month.monthNum && usage.year === month.yearNum)?.usage ??
        0;
      const prior = isPriorToStartDate(month);
      const placeholder = { label: monthLabel, value: undefined };

      // If prior to joining to EcoTrove, short circuit calculating an estimate value
      if (prior) {
        priorData.push({ label: monthLabel, value: monthValue });
        afterData.push(placeholder);
        estimateData.push({ ...placeholder });
        return;
      } else {
        afterData.push({ label: monthLabel, value: monthValue });
        priorData.push(placeholder);
      }

      // Next, get the estimated values for the month. A few things to note:
      // 1. We should not show estimate data for months prior to the user's start date (handled above)
      // 2. We should use actual historical data (if possible) over MUD data when determining an estimate
      // 3. If no historical data is available for the month, we can fall back on MUD data (if it exists)
      const matchingHistorical = data.historicalUsage.find(
        (usage) => usage.month === month.monthNum && usage.year === month.yearNum - 1,
      );
      const matchingMud = data.estimatedUsage.find((usage) => usage.month === month.monthNum);
      const estimatedValue = matchingHistorical?.usage ?? matchingMud?.usage ?? undefined;
      estimateData.push({ label: monthLabel, value: estimatedValue });

      // Finally, check if this month is the start month and set startData if so
      const startMonth = startDate && getUTCMonthData({ date: startDate });
      if (startMonth && month.monthNum === startMonth.monthNum && month.yearNum === startMonth.yearNum) {
        // Set the label to the max of actual or estimated value so it appears higher than both bars
        startData = {
          label: monthLabel,
          value: Math.max(monthValue, estimatedValue ?? 0),
        };
      }
    });

    // Format and return the chart options
    return {
      grid: {
        containLabel: true,
        left: 0,
        right: 0,
        bottom: 0,
        top: 110,
      },
      legend: {
        textStyle: TEXT_OPTIONS,
        icon: "pin",
        data: [DATASET_NAMES.before, DATASET_NAMES.after, DATASET_NAMES.estimate],
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        // Safe cast: we know usage values will always be numbers
        valueFormatter: (value) => `${(value as number).toFixed(2)} ${LABEL_BY_TYPE[type]}`,
      },
      // We use two xAxis values here to separate our two data sets (before EcoTrove, and after EcoTrove)
      // This allows us to center the bars of the before data on each month, while also showing the after/estimate bars side by side
      xAxis: [
        // Axis for prior data
        {
          type: "category",
          data: priorData.map(({ label }) => label),
          axisTick: { show: false },
          axisLabel: TEXT_OPTIONS,
        },
        // Axis for estimate vs. actual data
        {
          type: "category",
          data: estimateData.map(({ label }) => label),
          axisTick: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          ...TEXT_OPTIONS,
          formatter: `{value} ${LABEL_BY_TYPE[type]}`,
        },
      },
      series: [
        {
          name: DATASET_NAMES.before,
          xAxisIndex: 0,
          type: "bar",
          barMaxWidth: "40%",
          barCategoryGap: "20%",
          color: ECHARTS_NEUTRAL,
          itemStyle: ECHARTS_BAR_STYLE,
          data: priorData.map(({ value }) => value),
          // The markLine is added to this data set so that it is centered between the actual and estimated values on the start month
          markLine: startData && {
            data: [
              [
                { xAxis: startData.label, yAxis: 0 },
                {
                  name: "Joined\nEcoTrove",
                  xAxis: startData.label,
                  yAxis: startData.value,
                },
              ],
            ],
            label: TEXT_OPTIONS,
            symbol: [],
            tooltip: { show: false },
            emphasis: { disabled: true },
          },
        },
        {
          name: DATASET_NAMES.estimate,
          xAxisIndex: 1,
          type: "bar",
          color: ECHARTS_SECONDARY,
          barGap: 0,
          barCategoryGap: "20%",
          itemStyle: ECHARTS_BAR_STYLE,
          data: estimateData.map(({ value }) => value),
        },
        {
          name: DATASET_NAMES.after,
          xAxisIndex: 1,
          type: "bar",
          color: ECHARTS_PRIMARY,
          itemStyle: ECHARTS_BAR_STYLE,
          data: afterData.map(({ value }) => value),
        },
      ],
    };
  };

  return (
    <div className="border-default flex min-h-80 flex-col rounded-3xl p-4">
      <div className="flex w-full items-center justify-between">
        <h3 className="text-base-mobile font-semibold lg:text-base">Your {Utils.capitalize(type)} Usage</h3>
        <div
          role="button"
          className="daisy-btn daisy-btn-neutral daisy-btn-sm px-1"
          onClick={() => Utils.openDialogModal(modalId)}
        >
          <ArrowsPointingOutIcon className="hero-icon size-5" />
        </div>
      </div>
      {Boolean(!data.historicalUsage.length) && (
        <div className="size-full place-content-center">
          <p className="text-center text-sm">Not enough data yet!</p>
        </div>
      )}
      {Boolean(data.historicalUsage.length) && (
        <ReactECharts className="grow" theme="main" option={getChartOption(3)} />
      )}

      {/* Expanded usage modal */}
      <dialog id={modalId} className="daisy-modal p-content-mobile lg:p-content">
        <div className="daisy-modal-box flex min-h-full min-w-full flex-col p-6">
          <h3 className="text-base-mobile font-semibold lg:text-base">Your {type} usage</h3>
          <ReactECharts className="grow" theme="main" option={getChartOption(12)} />
          <div className="daisy-modal-action">
            <button className="daisy-btn daisy-btn-primary" onClick={() => Utils.closeDialogModal(modalId)}>
              Close
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};
