import { FirebaseApp, initializeApp } from "firebase/app";
import { doc, DocumentData, DocumentSnapshot, getFirestore, onSnapshot } from "firebase/firestore";
import { v5 as uuidv5 } from "uuid";
import { UtilityProvider } from "../../configs/UtilitiesConfig";

/** Stage for GCP infrastructure. */
export type Stage = "dev" | "prod";
/** Valid workflow name that exists in GCP. */
export type WorkflowName = "store-user-password-workflow"; // Add more workflow names here as they are built
/** Valid input to a workflow. */
export type WorkflowInput = Record<string, string>;
/** Handler for update events from a workflow. */
export type WorkflowUpdateHandler = (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => Promise<void>;

/**
 * Facade layer for calls to EcoTrove's Google Cloud Platform (GCP) infrastructure.
 */
export class GoogleCloudPlatformService {
  /** The GCP project ID. */
  private readonly projectId: string;
  /** The app that can be used for calls to Firebase. */
  private readonly firebaseApp: FirebaseApp;

  constructor(readonly region: "us-central1" = "us-central1") {
    this.projectId = process.env.REACT_APP_TESTING === "true" ? "terraform-dev-449320" : "terraform-prod-449320";
    this.firebaseApp = initializeApp({ projectId: this.projectId });
  }

  /**
   * Generates a deterministic UUID based on the user's email to be used as a GCP ID.
   * @param email the email to create the UUID with.
   * @returns a deterministic UUID that will always be the same for each email.
   */
  getUserId(email: string): string {
    // Use a custom UUID namespace across GCP calls to generate the same deterministic UUIDs.
    const userIdNamespace = "5c735c63-f5d4-4236-878e-175e42406b03";
    const formattedEmail = decodeURIComponent(email.trim().toLowerCase());
    return uuidv5(formattedEmail, userIdNamespace);
  }

  /**
   * Generates a deterministic UUID based on the utility provider + username combo.
   * @param utility the utility
   * @param username the username
   * @returns a deterministic UUID that will always be the same for each utility + username.
   */
  getCredentialId(utility: UtilityProvider, username: string): string {
    const credentialIdNamespace = "31c3f257-aa4e-4d87-88f7-540c204103d5";
    const formattedUsername = decodeURIComponent(username.trim().toLowerCase());
    const combo = `${utility}::${formattedUsername}`;
    return uuidv5(combo, credentialIdNamespace);
  }

  /**
   * Execute a GCP workflow with the provided input.
   * @param props the props to execute the workflow with.
   */
  async executeWorkflow(props: {
    /** The name of the workflow to execute. */
    name: WorkflowName;
    /** The input to execute the workflow with. */
    input: WorkflowInput;
    /** The handler to execute when a workflow update is published. */
    onUpdate: WorkflowUpdateHandler;
  }): Promise<() => void> {
    // Execute the workflow
    const { name, input, onUpdate } = props;
    const workflowId = `${name}-${this.projectId}`;
    const functionUrl = `https://${this.region}-${this.projectId}.cloudfunctions.net/execute-workflow-${this.projectId}`;
    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ workflow_id: workflowId, input }),
    });
    if (!response.ok) {
      throw new Error(
        `An error occurred while attempting to execute workflow (status: ${response.status}, message: ${await response.text()})`,
      );
    }
    const data = await response.json();
    if (!data || !data.execution_id) {
      throw new Error("No execution ID found in response");
    }

    // Add a listener to snapshot updates; this is where the backend will write status updates for the execution.
    // NOTE: Pub/Sub was explored as an option to do this but it's intended for backend only and doesn't work in React.
    // Instead, write to/from a dedicated Firestore database for real-time communication between GCP and React using `onSnapshot()`.
    const db = getFirestore(this.firebaseApp, `execution-statuses-${this.projectId}`);
    return onSnapshot(doc(db, workflowId, data.execution_id), onUpdate);
  }
}
