import React, { useEffect, useState } from "react";
import { MonthlyQuote, Plan, Utils } from "../utils";
import { CALENDLY_FEEDBACK_URL, FEEDBACK_DISCOUNT, REFER_ROUTE, REFERRAL_DISCOUNT } from "../constants";
import { useNavigate } from "react-router-dom";

interface Offer {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonAction: { type: "url"; url: string } | { type: "action"; action: () => Promise<void> };
}

/**
 * Props to pass to a `RDCCustomizedOffersCard`.
 */
export interface RDCCustomizedOffersCardProps {
  userData: {
    activePlan: Plan;
    email: string;
    monthlyQuote: MonthlyQuote | undefined;
    monthlySubscription: number | undefined;
    quoteUrl: string | undefined;
  };
}

/**
 * A list of customized offers based on a user's plan.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCustomizedOffersCard: React.FC<RDCCustomizedOffersCardProps> = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const [offers, setOffers] = useState<Offer[]>([]);

  useEffect(() => {
    // Populate offers
    const tempOffers: Offer[] = [];
    if (userData.activePlan === "Max Saver" || userData.activePlan === "Green Saver") {
      tempOffers.push({
        title: "Refer a Friend",
        subtitle: "Let your friends know about EcoTrove.",
        buttonText: `Refer for $${REFERRAL_DISCOUNT}`,
        buttonAction: { type: "url", url: REFER_ROUTE },
      });
    }
    if (userData.activePlan === "Free Insights") {
      tempOffers.push({
        title: "Switch to Max Saver",
        subtitle: "Go 1.5X greener and save up to 10%* next year.",
        buttonText: "Upgrade",
        buttonAction: {
          type: "action",
          action: async () =>
            await Utils.handleChangePlan({
              navigate,
              email: userData.email,
              currentPlan: userData.activePlan,
              currentSubscription: userData.monthlySubscription,
              newPlan: "Max Saver",
              monthlyQuote: userData.monthlyQuote,
              quoteUrl: userData.quoteUrl,
            }),
        },
      });
    }
    tempOffers.push({
      title: "Give us Feedback",
      subtitle: "Tell us your thoughts about EcoTrove.",
      buttonText: `Feedback for $${FEEDBACK_DISCOUNT}`,
      buttonAction: {
        type: "url",
        url: CALENDLY_FEEDBACK_URL,
      },
    });
    setOffers(tempOffers);
  }, [navigate, userData]);

  return (
    <div className="border-default flex flex-col gap-4 rounded-3xl p-6">
      <h2 className="text-lg">Customized Offers</h2>
      <ul className="flex flex-col gap-2">
        {offers.map(({ title, subtitle, buttonText, buttonAction }, i) => {
          const buttonClass = `daisy-btn ${
            i === 0 ? "daisy-btn-primary" : "daisy-btn-neutral"
          } lg:w-fit lg:justify-self-end`;
          return (
            <li
              key={i}
              className={`grid grid-cols-1 items-center gap-4 rounded-3xl p-4 lg:grid-cols-2 ${
                i === 0 ? "bg-accent" : ""
              }`}
            >
              <div>
                <h3 className="text-center text-base lg:text-start">{title}</h3>
                <p className="text-center text-sm text-neutral-content lg:text-start">{subtitle}</p>
              </div>
              {buttonAction.type === "url" ? (
                <a className={buttonClass} href={buttonAction.url} target="_blank" rel="noopener noreferrer">
                  {buttonText}
                </a>
              ) : (
                <button className={buttonClass} onClick={buttonAction.action}>
                  {buttonText}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
