import SkyrocketingEnergyCostsBlog from "../../images/blog/SkyrocketingEnergyCostsBlog.png";
import EnergyPricingBlog from "../../images/blog/EnergyPricingBlog.png";
import PowerOfCleanEnergyBlog from "../../images/blog/PowerOfCleanEnergyBlog.png";
import SerenaSavedBlog from "../../images/blog/SerenaSavedBlog.png";
import EmpoweringSmallBusinessesBlog from "../../images/blog/EmpoweringSmallBusinessesBlog.png";
import UnlockingEnergySavingsBlog from "../../images/blog/UnlockingEnergySavingsBlog.png";
import { FARMLINK_PARTNER_CONFIG } from "../../configs/BannerConfigs";
import { Utils } from "../../utils";

export const BLOG_CATEGORIES = ["Energy Policy", "About EcoTrove", "Tips and Tricks"] as const;
export type BlogCategory = (typeof BLOG_CATEGORIES)[number];
export type BlogImage = { src: string; alt: string };

const ALI_IMG_PATH = Utils.getPublicPath("images/team/AliSarilgan.png");
const OZGE_IMG_PATH = Utils.getPublicPath("images/team/OzgeIslegenWojdyla.png");

/**
 * Interface that represents a blog post.
 */
export interface Blog {
  id: string;
  title: string;
  subtitle: string;
  content: JSX.Element;
  category: BlogCategory;
  img?: BlogImage;
  author: { name: string; img: Omit<BlogImage, "alt"> };
  datePublishedEpochMS: number;
  minutesToRead: number;
  tags: string[];
}

// TODO: retrieve these from a CMS and not statically
const STATIC_BLOG_CONFIG: Record<string, Blog> = {
  "partnership-with-farmlink": {
    id: "partnership-with-farmlink",
    title: FARMLINK_PARTNER_CONFIG.modal.header,
    subtitle: "Learn about our partnership with The Farmlink Project.",
    category: "About EcoTrove",
    img: FARMLINK_PARTNER_CONFIG.modal.image
      ? {
          src: FARMLINK_PARTNER_CONFIG.modal.image.path,
          alt: FARMLINK_PARTNER_CONFIG.modal.image.alt,
        }
      : undefined,
    author: { name: "Ali Sarilgan", img: { src: ALI_IMG_PATH } },
    datePublishedEpochMS: 1740052800000,
    minutesToRead: 5,
    tags: ["EcoTrove", "Partnership", "Farmlink"],
    content: FARMLINK_PARTNER_CONFIG.modal.body,
  },
  "empowering-small-businesses": {
    id: "empowering-small-businesses",
    title: "Empowering Small Businesses",
    subtitle: "EcoTrove's Impact on Utility Budgeting",
    category: "About EcoTrove",
    img: {
      src: EmpoweringSmallBusinessesBlog,
      alt: "city landscape illustaration",
    },
    author: { name: "Ali Sarilgan", img: { src: ALI_IMG_PATH } },
    datePublishedEpochMS: 1731599736000,
    minutesToRead: 5,
    content: (
      <p>
        <br />
        For small businesses, managing cash flow and expenses is crucial to sustainability and growth. One of the most
        unpredictable and often overlooked expenses can be utility bills. Fluctuating energy costs can make budget
        planning challenging, particularly for small enterprises operating on tight margins. At EcoTrove, we understand
        these challenges and are dedicated to helping small businesses gain control over their utility expenses with
        predictable, fixed pricing.
        <br />
        <br />
        <strong>The Challenge of Utility Costs for Small Businesses</strong>
        <br />
        Utility expenses can account for a significant portion of a small business's operational costs. According to the{" "}
        <em>California Public Utilities Commission</em>, small businesses pay more expensive utility rates than large
        businesses, and utilities are one of their top expenses, often consuming up to 10% of total budgets. This
        percentage can be even higher depending on the nature of the business, location, and the energy efficiency of
        the buildings they occupy.
        <br />
        <br />
        <strong>EcoTrove's Solution: Predictability in Utilities</strong>
        <br />
        EcoTrove is revolutionizing how small businesses handle their energy costs by offering a fixed-price
        subscription model. This means that regardless of the season or market fluctuations, your utility bills remain
        consistent each month.
        <br />
        <br />
        <strong>Predictable Billing:</strong> With EcoTrove, small businesses know exactly what their utility bill will
        be each month, allowing for precise budgeting and financial planning. This predictability is essential for
        businesses that need to forecast expenses and manage cash flows effectively.
        <br />
        <br />
        <strong>No Surprises:</strong> Traditional utility billing can lead to significant spikes during periods of high
        usage, such as cold winters or hot summers, which can be a financial strain. EcoTrove eliminates this
        uncertainty, ensuring that businesses are not caught off-guard by unexpected high bills.
        <br />
        <br />
        <strong>Sustainability:</strong> By leveraging renewable energy sources, EcoTrove not only helps stabilize costs
        but also enables businesses to contribute to environmental sustainability. This can be a valuable aspect of a
        company’s CSR (Corporate Social Responsibility) strategy, appealing to eco-conscious consumers.
        <br />
        <br />
        <strong>The Bottom Line Impact:</strong> Switching to EcoTrove can help small businesses save on their utility
        expenses. By offering a fixed rate, we eliminate the risk of price spikes that can disrupt a business’s
        financial planning. Moreover, small businesses using EcoTrove can capture savings of approximately 6% annually
        compared to traditional variable utility rates. This saving translates directly to improved bottom lines and can
        be allocated to other critical areas of the business.
        <br />
        <br />
        <strong>Join the EcoTrove Community:</strong> If you’re a small business owner looking to stabilize your utility
        costs, consider EcoTrove as your partner in energy management. Reach out to us at{" "}
        <a href="mailto:info@ecotrove.com">info@ecotrove.com</a> with any questions.
      </p>
    ),
    tags: ["Customer", "EcoTrove"],
  },
  "unlocking-energy-savings": {
    id: "unlocking-energy-savings",
    title: "Unlocking Energy Savings",
    subtitle: "Smart Strategies for Every Homeowners",
    category: "Tips and Tricks",
    img: {
      src: UnlockingEnergySavingsBlog,
      alt: "connected smart appliances in a home saving money",
    },
    author: {
      name: "Ozge Islegen-Wojdyla",
      img: { src: OZGE_IMG_PATH },
    },
    datePublishedEpochMS: 1729180536000,
    minutesToRead: 4,
    content: (
      <p>
        <br />
        As global awareness about environmental sustainability grows, the shift towards clean energy has become more
        crucial than ever. At EcoTrove, we are committed to empowering you with knowledge and solutions that support
        this vital transition. Here’s why clean energy is not just beneficial, but essential, for a sustainable future.
        <br />
        <br />
        <strong>What is Clean Energy?</strong>
        <br />
        Clean energy refers to energy sources that produce minimal pollutants and emissions compared to traditional
        fossil fuels. This includes solar, wind, hydroelectric, and geothermal power. Unlike coal, oil, and natural gas,
        these resources provide renewable and sustainable energy without depleting the Earth’s natural resources.
        <br />
        <br />
        <strong>The Benefits of Clean Energy</strong>
        <br />
        <br />
        <strong>1. Environmental Impact:</strong>
        The most immediate benefit of clean energy is its low impact on the environment. Clean energy technologies
        produce little to no greenhouse gasses and pollutants, helping to reduce air pollution and combat climate
        change.
        <br />
        <br />
        <strong>2. Economic Advantages:</strong>
        Investing in clean energy technologies fosters job creation in new and emerging sectors. It also reduces
        dependence on imported fuels, keeping more economic capital within local economies and stabilizing energy prices
        by diversifying energy sources.
        <br />
        <br />
        <strong>3. Health Benefits:</strong>
        Reducing pollution from fossil fuels can have significant health benefits. Cleaner air leads to lower rates of
        asthma, lung disease, and other health issues related to air quality.
        <br />
        <br />
        <strong>4. Energy Independence:</strong>
        By increasing the use of local energy sources, regions and countries can achieve greater energy independence.
        This reduces vulnerability to foreign energy supply disruptions and geopolitical tensions.
        <br />
        <br />
        <strong>EcoTrove’s Commitment to Clean Energy</strong>
        <br />
        We understand that transitioning to renewable energy sources is not just about reducing emissions, but also
        about creating a sustainable economic future. Here’s how we are helping:
        <br />
        <br />
        <strong>Renewable Energy Plans:</strong>
        We offer energy plans that source electricity from renewable and clean energy projects. This allows our
        customers to power their homes and businesses with energy that is both green and cost-effective.
        <br />
        <br />
        <strong>Educational Initiatives:</strong>
        We believe knowledge is power. EcoTrove is committed to educating our community about the benefits of clean
        energy through workshops, webinars, and online resources.
        <br />
        <br />
        <strong>Support for Energy Upgrades:</strong>
        EcoTrove will soon begin offering resources and support for customers looking to make energy-efficient upgrades
        to their properties. This includes information on incentives for installing solar panels, special discounts on
        vetted products or upgrading to energy-efficient appliances.
        <br />
        <br />
        <strong>Join the Clean Energy Movement</strong>
        <br />
        Switching to clean energy is easier than ever, and it’s a change that can benefit everyone—economically,
        environmentally, and health-wise. If you’re ready to make a switch or just want to learn more about your
        options, EcoTrove is here to help.
      </p>
    ),
    tags: ["Tips and Tricks", "About EcoTrove"],
  },
  "how-serena-saved": {
    id: "how-serena-saved",
    title: "How Serena Saved with EcoTrove",
    subtitle: "Delivering Peace of Mind to Households",
    category: "About EcoTrove",
    img: {
      src: SerenaSavedBlog,
      alt: "graphioc of an EcoTrove customer saving",
    },
    author: { name: "Ali Sarilgan", img: { src: ALI_IMG_PATH } },
    datePublishedEpochMS: 1726761336000,
    minutesToRead: 5,
    content: (
      <p>
        <br />
        In a world where utility bills are as unpredictable as the weather, one customer found stability and savings
        with EcoTrove. Meet Serena, a resident of East Palo Alto, California, who experienced firsthand the
        unpredictable nature of traditional utility services and how EcoTrove transformed her energy expenses into
        predictable payments.
        <br />
        <br />
        <strong>The Challenge with Traditional Utilities</strong>
        <br />
        Like many Californians, Serena was accustomed to the erratic nature of her energy bills. With PG&E, her monthly
        bills ranged from $116 to a steep $216, averaging around $175. This fluctuation made budgeting a monthly gamble,
        adding financial stress to her daily life. The inconsistency wasn’t just a nuisance—it was a financial burden
        that many households across America face, with utility bills devouring an average of 10% of their take-home pay.
        <br />
        <br />
        <strong>The EcoTrove Solution</strong>
        <br />
        When Serena switched to EcoTrove, her utility bills transformed. She started paying a flat rate of $164.99 per
        month, regardless of seasonal spikes in usage. This change didn’t just represent a financial saving of about 6%
        annually compared to her average costs with PG&E—it also brought a much-needed predictability to her finances.
        <br />
        <br />
        <strong>Why Stability Matters</strong>
        <br />
        The advantage of EcoTrove isn’t only in the numbers. It’s about the peace of mind that comes from knowing
        exactly what your bill will be each month. For Serena, this meant no more surprises and no need to sacrifice
        other necessities to accommodate an unexpectedly high utility bill. This stability is particularly crucial for
        families budgeting on a fixed income or those trying to save for the future.
        <br />
        <br />
        <strong>Sustainable Energy for a Sustainable Future</strong>
        <br />
        EcoTrove doesn’t just help stabilize financial planning—it also contributes to a greener planet. By harnessing
        renewable energy sources, which are less prone to price volatility than fossil fuels, EcoTrove ensures that
        every kilowatt of electricity you use is helping to reduce carbon emissions.
        <br />
        <br />
        <strong>A Brighter Future with EcoTrove</strong>
        <br />
        Serena’s story is just one of many. As more households switch to EcoTrove, they not only gain control over their
        energy expenses but also contribute to a larger movement towards a sustainable and resilient energy future. At
        EcoTrove, we believe that everyone deserves to live in comfort and make energy choices that fit their budget
        without compromising on the value they receive.
      </p>
    ),
    tags: ["Customer", "About EcoTrove"],
  },
  "battling-skyrocketing-energy-costs": {
    id: "battling-skyrocketing-energy-costs",
    title: "Battling Skyrocketing Energy Costs",
    subtitle: "How EcoTrove Supports Small Businesses.",
    category: "About EcoTrove",
    img: {
      src: SkyrocketingEnergyCostsBlog,
      alt: "hot day with buildings and a thermometer",
    },
    author: { name: "Ali Sarilgan", img: { src: ALI_IMG_PATH } },
    datePublishedEpochMS: 1717243200000,
    minutesToRead: 5,
    content: (
      <p>
        <span className="font-semibold">"I lost customers because I can't afford to run my AC."</span>
        <br />
        <br />
        This is what a California retailer confided in us recently. He's been operating his small shop selling artisanal
        products in San Jose for over 30 years, yet now he's facing a challenge that's threatening his business—not a
        lack of customers, not inflation, but soaring utility bills. This summer, his energy costs have ballooned to 21%
        of his store's revenue, up from just 5% in the mild spring months. For a small business running on thin margins,
        this kind of volatility is unsustainable.
        <br />
        <br />
        His solution? He stopped using AC. But in a state where summer afternoons regularly exceed 90°F, this decision
        led to a sharp drop in customer visits during the critical 4-9 p.m. hours when PG&E's rates spike along with the
        temperature. He found himself stuck between a rock and a hard place: run the AC and face financial ruin, or turn
        it off and watch his foot traffic disappear.
        <br />
        <br />
        Sadly, his story is far from unique. As electricity prices rise nationwide at nearly double the rate of overall
        consumer prices, small businesses like his are struggling to keep the lights on—literally. From grocery stores
        to hair salons, many are left to navigate the complex and fast-changing landscape of energy policies and
        products on their own.
        <br />
        <br />
        This is where EcoTrove steps in. We're not just another energy provider—we're a partner in energy management,
        especially for small businesses who are tired of playing a guessing game with their utility bills. Our solution?
        A fixed-price subscription to cheaper, greener power that eliminates the peaks and valleys of traditional
        billing. You pay us the same amount every month, and we handle the rest, from switching your energy source to a
        renewable generator to negotiating discounts on your behalf.
        <br />
        <br />
        But we don't stop there. Our AI-powered energy advisor is like having a personal energy consultant on call 24/7.
        It recommends energy-efficient products, rebates, and incentives tailored to your building's characteristics,
        climate zone, and usage patterns. As your business adopts smarter appliances, our advisor will automatically
        adjust settings to optimize savings, so you can focus on what really matters—running your business.
        <br />
        <br />
        At EcoTrove, our mission is simple: to give you the peace of mind to use the power you need, without the shock
        of unpredictable energy bills. If you're a small business owner looking to stabilize your utility costs and make
        your operations more sustainable, consider EcoTrove as your trusted ally in energy management.
      </p>
    ),
    tags: ["Customer", "About EcoTrove"],
  },
  "navigating-the-new-pge-pricing": {
    id: "navigating-the-new-pge-pricing",
    title: "Navigating the New PG&E Pricing",
    subtitle: "What you need to know about the new fixed electricity charges.",
    category: "Energy Policy",
    img: {
      src: EnergyPricingBlog,
      alt: "scales balancing electricity and renewable energy",
    },
    author: {
      name: "Ozge Islegen-Wojdyla",
      img: { src: OZGE_IMG_PATH },
    },
    datePublishedEpochMS: 1718120284000,
    minutesToRead: 5,
    content: (
      <p>
        <br />
        In 2024, Pacific Gas and Electric Co. (PG&E) has implemented significant changes to its pricing structure, which
        has sparked debate among Californians. With the state’s electricity prices now among the highest in the nation,
        understanding these changes is crucial for every PG&E customer.
        <br />
        <br />
        <strong>What’s Changing with PG&E’s Pricing?</strong>
        <br />
        <br />
        <strong>Rate Increases:</strong>
        <br />
        Starting in January, PG&E introduced a substantial rate hike of 20%, which they attribute to necessary updates
        to their aging infrastructure. This increase is intended to enhance efficiency and bolster the grid against the
        impacts of climate change. However, this adjustment means that typical households will see their annual bills
        increase by more than $400. Compared to May of 2023, electricity prices are at least 27% higher in May 2024.
        <br />
        <br />
        <strong>Introduction of Fixed Monthly Charges:</strong>
        <br />
        Adding another layer to the pricing structure, PG&E plans to introduce a fixed monthly charge of approximately
        $24, starting in 2026. This charge is part of a broader strategy to make billing more predictable and to
        distribute the cost of grid maintenance more evenly among all users. The fixed charge will vary based on income,
        with proposed rates of $6, $12, or $24.15 per month.
        <br />
        <br />
        <strong>What's the Impact of These Changes?</strong>
        <br />
        <br />
        <strong>Pros:</strong>
        <br />
        <em>Support for Infrastructure:</em> The additional funds will be used to upgrade essential components of the
        electrical grid, preparing it for future challenges.
        <br />
        <em>Predictability:</em> The new fixed charges aim to stabilize monthly bills to some degree and reduce monthly
        variances.
        <br />
        <br />
        <strong>Cons:</strong>
        <br />
        <em>Increased Financial Burden:</em> The rate hike and new fixed charges may disproportionately affect
        lower-income households and those who use less electricity, potentially worsening economic inequality.
        <br />
        <em>Controversy Over Fairness:</em> There is ongoing debate about whether these changes fairly distribute costs
        among different economic groups, especially during economic downturns.
        <br />
        <br />
        <strong>How Can You Mitigate the Impact?</strong>
        <br />
        <br />
        <strong>Explore Alternative Energy Solutions:</strong>
        <br />
        Consider switching to renewable energy sources, such as solar, which can offer more stable pricing and reduce
        dependence on utility companies.
        <br />
        <br />
        <strong>Energy Efficiency Measures:</strong>
        <br />
        Invest in energy-efficient appliances, insulation, and smart home devices that help reduce overall consumption
        and lower your bills.
        <br />
        <br />
        <strong>Stay Informed and Engaged:</strong>
        <br />
        Participate in community discussions and public hearings on energy pricing to voice your concerns and stay
        informed about changes that may affect you.
        <br />
        <br />
        <strong>Try EcoTrove:</strong>
        <br />
        We guarantee fixed monthly pricing for your utility bills, no matter what new pricing structure PG&E rolls out
        or price increases they implement.
      </p>
    ),
    tags: ["Customer", "Energy Policy"],
  },
  "power-of-clean-energy": {
    id: "power-of-clean-energy",
    title: "The Power of Clean Energy",
    subtitle: "Transforming Our World",
    category: "Energy Policy",
    img: {
      src: PowerOfCleanEnergyBlog,
      alt: "model of the world with clean energy sources",
    },
    author: { name: "Ali Sarilgan", img: { src: ALI_IMG_PATH } },
    datePublishedEpochMS: 1720539484000,
    minutesToRead: 4,
    content: (
      <p>
        <br />
        As global awareness about environmental sustainability grows, the shift towards clean energy has become more
        crucial than ever. At EcoTrove, we are committed to empowering you with knowledge and solutions that support
        this vital transition. Here’s why clean energy is not just beneficial, but essential, for a sustainable future.
        <br />
        <br />
        <strong>What is Clean Energy?</strong>
        <br />
        Clean energy refers to energy sources that produce minimal pollutants and emissions compared to traditional
        fossil fuels. This includes solar, wind, hydroelectric, and geothermal power. Unlike coal, oil, and natural gas,
        these resources provide renewable and sustainable energy without depleting the Earth’s natural resources.
        <br />
        <br />
        <strong>The Benefits of Clean Energy</strong>
        <br />
        <br />
        <strong>1. Environmental Impact:</strong>
        The most immediate benefit of clean energy is its low impact on the environment. Clean energy technologies
        produce little to no greenhouse gasses and pollutants, helping to reduce air pollution and combat climate
        change.
        <br />
        <br />
        <strong>2. Economic Advantages:</strong>
        Investing in clean energy technologies fosters job creation in new and emerging sectors. It also reduces
        dependence on imported fuels, keeping more economic capital within local economies and stabilizing energy prices
        by diversifying energy sources.
        <br />
        <br />
        <strong>3. Health Benefits:</strong>
        Reducing pollution from fossil fuels can have significant health benefits. Cleaner air leads to lower rates of
        asthma, lung disease, and other health issues related to air quality.
        <br />
        <br />
        <strong>4. Energy Independence:</strong>
        By increasing the use of local energy sources, regions and countries can achieve greater energy independence.
        This reduces vulnerability to foreign energy supply disruptions and geopolitical tensions.
        <br />
        <br />
        <strong>EcoTrove’s Commitment to Clean Energy</strong>
        <br />
        We understand that transitioning to renewable energy sources is not just about reducing emissions, but also
        about creating a sustainable economic future. Here’s how we are helping:
        <br />
        <br />
        <strong>Renewable Energy Plans:</strong>
        We offer energy plans that source electricity from renewable and clean energy projects. This allows our
        customers to power their homes and businesses with energy that is both green and cost-effective.
        <br />
        <br />
        <strong>Educational Initiatives:</strong>
        We believe knowledge is power. EcoTrove is committed to educating our community about the benefits of clean
        energy through workshops, webinars, and online resources.
        <br />
        <br />
        <strong>Support for Energy Upgrades:</strong>
        EcoTrove will soon begin offering resources and support for customers looking to make energy-efficient upgrades
        to their properties. This includes information on incentives for installing solar panels, special discounts on
        vetted products or upgrading to energy-efficient appliances.
        <br />
        <br />
        <strong>Join the Clean Energy Movement</strong>
        <br />
        Switching to clean energy is easier than ever, and it’s a change that can benefit everyone—economically,
        environmentally, and health-wise. If you’re ready to make a switch or just want to learn more about your
        options, EcoTrove is here to help.
      </p>
    ),
    tags: ["Clean Energy", "Energy Policy"],
  },
};

export type GetBlogsFilters = {
  maxNum?: number;
  category?: BlogCategory;
  searchString?: string;
};

/**
 * Class that vends calls for blogs.
 */
export class BlogService {
  /**
   * Retrieves an array of blogs with the provided options.
   * @param filters the filters to retrieve the blogs with
   * @returns the array of blogs.
   */
  static getBlogs = (filters: GetBlogsFilters): Blog[] => {
    const { maxNum, category, searchString } = filters;
    // Intentional use of != here to check against null and undefined
    if (maxNum != null && maxNum <= 0) throw new Error("maxNum must be greater than 0");
    const searchWords = searchString?.trim()?.split(" ");

    let blogs = Object.values(STATIC_BLOG_CONFIG);
    if (category) blogs = blogs.filter((blog) => blog.category === category);
    // Very simplified search functionality; consider improving in the future
    if (searchWords?.length) {
      blogs = blogs.filter((blog) =>
        searchWords.some(
          (word) =>
            blog.title.toLowerCase().includes(word.toLowerCase()) ||
            blog.subtitle.toLowerCase().includes(word.toLowerCase()),
        ),
      );
    }
    if (maxNum) blogs = blogs.slice(0, maxNum);

    return blogs;
  };

  /**
   * Retrieves a single blog.
   * @param id the ID of the blog to retrieve
   * @returns the blog if found, or undefined otherwise.
   */
  static getBlog = (id: string): Blog | undefined => {
    return STATIC_BLOG_CONFIG[id];
  };
}
