import { MouseEventHandler, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Utils } from "../../utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

/**
 * Props to pass to a `Modal`.
 */
export interface ModalProps {
  /** The ID of the modal; can be referenced to manually open/close the modal. */
  id: string;
  /** Additional class(es) to apply to the modal. */
  className?: string;
  /** Invoke a callback when the modal is closed. */
  onClose?: () => void;
}

/**
 * Popup modal component.
 * NOTE: createPortal is used here to ensure that no width, height, overflow, etc. restrictions are placed on the modal by parent elements.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const Modal: React.FC<ModalProps & PropsWithChildren> = (props) => {
  const { id, className, onClose, children } = props;

  // Handle close events
  const handleClose: MouseEventHandler = (e) => {
    e.preventDefault();
    Utils.closeDialogModal(id);
    if (onClose) onClose();
  };

  return createPortal(
    <dialog id={id} data-testid={id} className="daisy-modal p-content-mobile lg:p-content">
      <div className={`daisy-modal-box max-h-full max-w-full ${className}`}>
        {/* Close button in top corner */}
        <button
          data-testid={`${id}-close`}
          className="daisy-btn daisy-btn-circle daisy-btn-ghost absolute right-4 top-4 z-40 bg-base-100"
          onClick={handleClose}
          aria-label="close"
        >
          <XMarkIcon className="hero-icon" />
        </button>
        {children}
      </div>
      {/* Allow the modal to be closed by clicking outside of it */}
      <form method="dialog" className="daisy-modal-backdrop">
        <button onClick={handleClose} aria-label="close" />
      </form>
    </dialog>,
    document.body,
  );
};
