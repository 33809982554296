import { useEffect, useRef, useState } from "react";
import { RDCStatusCodePage } from "../RDCStatusCodePage";
import { useParams } from "react-router-dom";
import { usePartnerStore, useReferrerStore } from "../../stores";
import { Utils } from "../../utils";

export const VALID_PARTNERS = ["business", "diana"];

/**
 * Props to pass to a `CheckParameterPage`.
 */
export interface CheckParameterPageProps {
  /** The name of the parameter to check. */
  parameter: "partner" | "referrer";
}

/**
 * A redirect page that:
 *   (a) checks for certain parameters
 *   (b) sets global state(s) based on those parameters, and
 *   (c) redirects to the home page.
 * These parameters are used for various operations/calculations across the site.
 */
export const CheckParameterPage: React.FC<CheckParameterPageProps> = (props) => {
  const { parameter } = props;
  const params = useParams();
  const { setPartner } = usePartnerStore();
  const { setReferrer } = useReferrerStore();
  const [errorOccurred, setErrorOccurred] = useState(false);
  const redirectInitiated = useRef(false);

  /**
   * Check for all possible search parameters.
   */
  useEffect(() => {
    const checkParams = async () => {
      try {
        // Short circuit evaluation if a redirect has already been initiated
        if (redirectInitiated.current) return;
        else redirectInitiated.current = true;

        const url = new URL(window.location.origin);
        switch (parameter) {
          // Check for partner referrals
          case "partner":
            const partner = params.partner?.toLowerCase() ?? "";
            if (VALID_PARTNERS.includes(partner)) {
              setPartner(partner);
              url.searchParams.set("partner", partner);
            }
            break;
          // Check for standard referrals
          case "referrer":
            const referrer = params.referrer?.toLowerCase() ?? "";
            const isPayingUser = await Utils.isPayingUser(referrer);
            if (isPayingUser.result) {
              setReferrer({ email: referrer, name: isPayingUser.name });
              url.searchParams.set("referrer", referrer);
            }
            break;
          // Defensive programming
          default:
            throw new Error(`invalid parameter: ${parameter}`);
        }

        // Redirect to home
        window.location.replace(url);
        return;
      } catch (error) {
        setErrorOccurred(true);
      }
    };

    checkParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorOccurred ? <RDCStatusCodePage code={500} /> : <div data-testid="check-parameter" />;
};
