import { useParams } from "react-router-dom";
import { RDCActionCard, RDCActionCardProps, CardWithBackground, RDCContactUsModal } from "../components";
import {
  CALENDLY_DEMO_URL,
  ECOTROVE_EMAIL_INFO,
  GA_CONVERSION_ID_SCHEDULE_CALL_CLICK,
  GA_CONVERSION_ID_SHOW_QUOTE_PAGE,
} from "../constants";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import mixpanel from "mixpanel-browser";
import { Utils } from "../utils";
import { useEffect } from "react";
import { GoogleAdsService } from "../services";

// TODO: deprecate this page and move to Webflow
// This type is a leftover from a legacy version of this page which supported multiple types
type CustomerType = "residential";

const CONTACT_US_MODAL_ID_RESIDENTIAL_QUOTE = "contact-us-modal-residential-quote";

/**
 * Page that displays a quote for a residential customer.
 * @returns a React component.
 */
export const RDCResidentialQuotePage: React.FC = () => {
  const params = useParams();

  const CONFIG: Record<CustomerType, { blurb: JSX.Element; cta?: RDCActionCardProps }> = {
    // Residential config
    residential: {
      blurb: (
        <>
          <p>
            We detected that you are a residential customer and are ineligible for our service. Please contact{" "}
            <a href={`mailto:${ECOTROVE_EMAIL_INFO}`} className="daisy-link-hover daisy-link daisy-link-primary">
              info@ecotrove.com
            </a>{" "}
            with any questions.
          </p>
          <br />
          <p>Thank you!</p>
        </>
      ),
    },
  };

  useEffect(() => {
    // Track analytics
    mixpanel.track(`showQuote${Utils.capitalize(params.type ?? "")}`);
    GoogleAdsService.event(GA_CONVERSION_ID_SHOW_QUOTE_PAGE);
  }, [params.type]);

  // This is hardcoded from a legacy version of this page
  const type = "residential";
  const { blurb, cta } = CONFIG[type];

  return (
    <div>
      <RDCContactUsModal modalId={CONTACT_US_MODAL_ID_RESIDENTIAL_QUOTE} />
      <CardWithBackground>
        {/* Header */}
        <div className="flex flex-col items-center gap-default">
          <h1 className="text-lg">Thanks for trying EcoTrove!</h1>
          {Boolean(cta) && <h1 className="text-4xl">Predictable Energy</h1>}
        </div>
        {/* Blurb */}
        <div className="text-center text-neutral-content">{blurb}</div>
        {/* Action cards */}
        {cta && (
          <div className="flex w-full flex-col items-center justify-between gap-default">
            <div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
              <RDCActionCard {...cta} />
              <RDCActionCard
                title="Need a Consultation?"
                subTitle="Schedule a free consultation call with one of our energy experts."
                body={
                  <div className="flex w-full flex-col items-center">
                    <CalendarDaysIcon className="hero-icon size-32 self-center text-neutral-content" />
                  </div>
                }
                action={{
                  type: "link",
                  text: "Schedule Call",
                  href: CALENDLY_DEMO_URL,
                  onClick: () => {
                    mixpanel.track(`click schedule call on ${type} show quote page`);
                    GoogleAdsService.event(GA_CONVERSION_ID_SCHEDULE_CALL_CLICK);
                  },
                }}
              />
            </div>
          </div>
        )}
      </CardWithBackground>
    </div>
  );
};
