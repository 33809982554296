import { Utils } from "../../utils";
import { PartnerBannerProps } from "../../components";

/**
 * ID of the modal for Farmlink's partner banner.
 */
export const FARMLINK_PARTNER_MODAL_ID = "farmlink-partner-modal";

/**
 * Config for Farmlink's partner banner.
 */
export const FARMLINK_PARTNER_CONFIG: PartnerBannerProps = {
  name: "Farmlink",
  banner: {
    image: {
      path: Utils.getPublicPath("images/partners/Farmlink/Logo.svg"),
      alt: "Farmlink logo",
    },
    message: "Read about our founding story and partnership with The Farmlink Project.",
  },
  modal: {
    id: FARMLINK_PARTNER_MODAL_ID,
    image: {
      path: Utils.getPublicPath("images/partners/Farmlink/Banner.png"),
      alt: "Farmers' market",
    },
    header: "Why Do We Accept Utility Bills That Would Make No Sense Anywhere Else?",
    author: { name: "Ali", title: "EcoTrove CEO & Co-Founder" },
    body: (
      <>
        <p>
          Would you use Netflix if they charged you a different price each month? How about AT&T if they doubled your
          bill on Christmas because you use your phone more?
        </p>
        <br />
        <p>
          If your landlord starts 3 separate kitchen fires in your home in 4 years, and adds a 20% “extraordinary
          increase” on your rent after each of those times – would you happily pay it?
        </p>
        <br />
        <p>
          While your gut might scream “No!” to these hypothetically deceptive corporate relationships, you have a real
          one in your life right now… Power bills. Your utility charges you more and more for similar levels of power.
          You can save on power and stabilize your bills so that you can put cash back into your business, not into your
          utility.
        </p>
        <br />
        <p>
          Power bills have been growing 3x faster than inflation and have doubled in the past decade. Today, power bills
          are the second largest expense for households and brick & mortar businesses after rent. And unlike rent, they
          are deeply unpredictable with huge month-to-month swings. This is unacceptable.
        </p>
        <br />
        <p>
          At EcoTrove, our goal is simple: we want you to pay less to your utility and know exactly how much your power
          will cost. Our AI Energy Agent optimizes your online utility accounts to replace their unpredictable bills
          with a flat-price subscription to lower-cost energy. Our average customer saves 7% off their power bill, pays
          the same predictable price for power each month and uses cleaner energy.
        </p>
        <br />
        <p>
          After college, I consulted for utilities whose revolutionary strategy was basically “charge more, cut costs,
          try not to start massive fires.” Later, I led corporate strategy at GameStop after the meme-stock saga where I
          struggled to forecast utility bills for 2,500+ stores. Every part of the energy system seemed broken, but
          luckily I met a co-founder who agreed. Ozge studied energy pricing during her PhD at Stanford and told me,
          “Power billing is complex, but there are plenty of ways to save, if you know where to look.”
        </p>
        <br />
        <p>
          At EcoTrove, we're making power bills predictable and cheaper so that no home or business needs to choose
          between paying their power bill or covering essential expenses. That's why we're proud to partner with The
          Farmlink Project, a nonprofit delivering farm fresh meals to families in need.
        </p>
        <br />
        <p>
          Just as we help avoid waste in power bills, The Farmlink Project fights food waste and has already delivered
          over 300 million pounds of food across the US. We will be donating $50 to their cause for each new EcoTrove
          sign-up over the next month that will directly help families affected by the recent California wildfires.
        </p>
        <br />
        <p>
          Both our expansion and partnership with the Farmlink Project have been made possible by our pre-seed
          investors, Pear VC and e2.vc. Come lower your power bills with EcoTrove, and help us turn our investment round
          into meals for those who need them most!
        </p>
      </>
    ),
  },
};
