import { useParams } from "react-router-dom";
import { CardWithBackground } from "../components";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

/**
 * Page that notifies a user to check their email for confirmation.
 */
export const RDCConfirmEmailPage: React.FC = () => {
  const { email } = useParams();

  useEffect(() => {
    mixpanel.track("confirmEmail");
  }, []);

  return (
    <div>
      <CardWithBackground>
        <h1 className="overflow-auto text-center text-xl">
          We have sent a confirmation email to <b>{email}</b>.<br />
          <br />
          Please confirm to log in.
        </h1>
      </CardWithBackground>
    </div>
  );
};
