import { useState, useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const ROTATING_SUBTITLES = [
  "PG&E prices are 27% higher this year.",
  "The average EcoTrove customer saves 7% off their utility bills.",
  "The average EcoTrove customer uses 32% more green energy.",
  "You can lock in fixed monthly pricing with EcoTrove today!",
  "Let your energy concierge turn your bills cheaper, greener and more predictable.",
];

/**
 * @description Displays a static title and rotates subtitles every 5 seconds.
 * @returns A styled card with dynamic subtitles.
 */
export const RDCRotatingCard: React.FC = () => {
  const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState(0);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentSubtitleIndex((prevIndex) => (prevIndex + 1) % ROTATING_SUBTITLES.length);
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(rotationInterval); // Cleanup on unmount
  }, []);

  return (
    <div className="mt-4 flex justify-center">
      <div className="flex h-auto w-full max-w-lg flex-col items-center justify-center rounded-3xl border-2 border-gray-300 bg-white p-2 text-center shadow-none">
        {/* Title with Icon */}
        <div className="flex items-center justify-center gap-1">
          <InformationCircleIcon className="h-5 w-5 text-gray-600" />
          <p className="text-lg text-gray-800">Did you know?</p>
        </div>
        {/* Rotating Subtitle */}
        <p className="mt-1 text-sm text-gray-700">{ROTATING_SUBTITLES[currentSubtitleIndex]}</p>
      </div>
    </div>
  );
};
