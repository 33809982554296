import { SubmitHandler, useForm } from "react-hook-form";
import { RDCCaptcha, CardWithBackground, RDCConfirmPasswordsInput, RDCValidationError } from "../components";
import { EMAIL_REGEX, LOGIN_ROUTE, FULL_NAME_REGEX, USER_DASHBOARD_ROUTE, CONFIRM_EMAIL_ROUTE } from "../constants";
import { EnvelopeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "../supabaseClient";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";
import { Helmet } from "react-helmet";
import { Utils } from "../utils";

const GET_QUOTE_MODAL_ID = "get-quote-modal";
const ACKNOWLEDGE_DIFFERENT_EMAIL_MODAL_ID = "acknowledge-different-email-modal";

interface CreateAccountForm {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

/**
 * Form page that allows a user to create an account.
 * @returns a React component.
 */
export const RDCCreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setErrorAlert } = useAlertStore();
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [captchaInvalid, setCaptchaInvalid] = useState<boolean>(false);
  const [captchaKey, setCaptchaKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [attemptedEmail, setAttemptedEmail] = useState<string>();

  const createAccount = useForm<CreateAccountForm>();
  const { errors } = createAccount.formState;

  useEffect(() => {
    mixpanel.track("createAccount");
  }, []);

  useEffect(() => {
    checkLoggedIn();
    checkUserProfile();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Helper method that checks if a user is already logged in, and redirects them if so.
   */
  const checkLoggedIn = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) navigate(USER_DASHBOARD_ROUTE);
  };

  /**
   * Helper method that checks if an email is provided as a param, and prepopulates fields if so.
   */
  const checkUserProfile = async () => {
    try {
      const email = searchParams.get("email")?.toLowerCase();
      if (email) {
        createAccount.setValue("email", email);
        const { href } = window.location;
        const { data, error } = await supabase.from("user-profiles").select().eq("email", email);
        if (error) throw error;
        if (!data.length || !data[0]?.name) return;
        // If user found, update the DB with the URL and populate the form with their name
        await supabase.from("user-profiles").update({ create_account_url: href }).eq("email", email);
        createAccount.setValue("name", data[0].name);
      }
    } catch (error) {
      setErrorAlert("An error occurred while fetching user profile. Please try again later.");
    }
  };

  /**
   * Handler for create account events.
   * @param data the data to create the account with.
   */
  const handleCreateAccount: SubmitHandler<CreateAccountForm & { showEmailAcknowledgement: boolean }> = async ({
    name,
    email,
    password,
    showEmailAcknowledgement,
  }) => {
    setLoading(true);
    setAttemptedEmail(email);

    try {
      // Check captcha
      if (!captchaToken) {
        setCaptchaInvalid(true);
        return;
      }
      setCaptchaInvalid(false);

      // Double check that an account doesn't already exist for the utility account
      const utilityEmail = searchParams.get("email")?.toLowerCase();
      if (utilityEmail) {
        const { data: existingData } = await supabase
          .from("user-profiles")
          .select("user_id")
          .eq("email", utilityEmail)
          .single();
        if (existingData?.user_id) {
          setErrorAlert(
            `An account with your utility email (${utilityEmail}) already exists. Please log in with that email instead.`,
          );
          return;
        }
      }

      // If the user signed into their utility with a different email than the one submitted in this form,
      // The email submitted in this form should be used for their account instead of the utility email.
      // However, we first prompt the user to confirm this is their intention in case they mistyped their email.
      if (utilityEmail && utilityEmail !== email && showEmailAcknowledgement) {
        Utils.openDialogModal(ACKNOWLEDGE_DIFFERENT_EMAIL_MODAL_ID);
        return;
      }
      const existingEmail = utilityEmail ?? email;

      // Check if user is a paid user; if not, we take the user through get quote flow
      if (!(await Utils.isPayingUser(existingEmail)).result) {
        Utils.openDialogModal(GET_QUOTE_MODAL_ID);
        return;
      }

      // Sign the user up with the email they submitted in this form (NOT necessarily their utility email)
      const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: "https://ecotrove.com/login",
          captchaToken,
        },
      });

      // Check the auth response for errors
      if (signUpError) {
        if (signUpError.code === "23505") {
          setErrorAlert("An account with this email already exists. Please log in instead.");
          return;
        }
        throw new Error(signUpError.message);
      }
      const user = signUpData?.user;
      if (user?.identities === undefined || !user?.identities.length) {
        setErrorAlert("An account with this email already exists. Please log in instead.");
        return;
      }

      // Update user profiles, service accounts, and all associated tables with user_id
      // Note that the user may choose to change their email to a different one than their utility email here
      // Therefore, update all tables below with the email that is submitted in this form
      const id = user.id;
      await Promise.all([
        supabase
          .from("user-profiles")
          .update({ user_id: id, name, active_account: true, email })
          .eq("email", existingEmail),
        supabase.from("service-accounts").update({ user_id: id, email }).eq("email", existingEmail),
        supabase.from("terms-and-conditions").update({ user_id: id, email }).eq("email", existingEmail),
        supabase.from("user-bills").update({ user_id: id, email }).eq("email", existingEmail),
        supabase.from("savings-claimed").update({ user_id: id, email }).eq("email", existingEmail),
      ]);

      // Redirect to the confirm-email page
      navigate(`${CONFIRM_EMAIL_ROUTE}/${encodeURIComponent(email)}`);
    } catch (error) {
      setErrorAlert("An error occurred while attempting to create account. Please try again later.");
      return;
    } finally {
      // Increment captchaKey to force the captcha token to refresh
      setCaptchaKey((prev) => prev + 1);
      setLoading(false);
    }
  };

  /**
   * Handler for "acknowledge different email" events.
   * If this handler is fired, the user has confirmed that they want to use a different email than their utility account.
   * In this case, we re-submit the form but with a boolean indicating that the user acknowledged the email disparity.
   */
  const handleAcknowledgeDifferentEmail: MouseEventHandler = (e) => {
    e.preventDefault();
    Utils.closeDialogModal(ACKNOWLEDGE_DIFFERENT_EMAIL_MODAL_ID);
    createAccount.handleSubmit((data) =>
      handleCreateAccount({
        ...data,
        email: data.email.toLowerCase(),
        showEmailAcknowledgement: false,
      }),
    )();
  };

  /**
   * Handler for get quote button events.
   * @param e the button event
   */
  const handleGetQuote: MouseEventHandler = async (e) => {
    e.preventDefault();
    try {
      if (attemptedEmail) {
        const { data, error } = await supabase.from("user-profiles").select("quote_url").eq("email", attemptedEmail);
        if (error) throw new Error(error.message);
        if (data && data.length && data[0].quote_url) {
          // Extract the pathname and search string so the origin is preserved in dev vs. prod
          const url = new URL(data[0].quote_url);
          navigate(`${url.pathname}${url.search}`);
          return;
        }
      }
      navigate("/");
    } catch (error) {
      setErrorAlert(
        `An error occurred while attempting to get your quote. Please try again later or visit https://ecotrove.com to get a quote.`,
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>EcoTrove | Create Account</title>
        <meta
          name="description"
          content="Create an EcoTrove account to save on your energy bills while using cleaner, predictable power. ."
        />
        <meta
          name="keywords"
          content="create ecotrove account, affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power, ecotrove team, Jess Kerlin, Tegan Fleishman, Christine Manegan"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="w-full">
        {/* Acknowledge different email modal */}
        <dialog id={ACKNOWLEDGE_DIFFERENT_EMAIL_MODAL_ID} className="daisy-modal p-content-mobile lg:p-content">
          <div className="daisy-modal-box flex flex-col gap-4 p-6">
            <h1 className="text-xl">Does this look correct?</h1>
            <p>
              The email you entered does not match our records for your utility account{" "}
              <b>({searchParams.get("email")?.toLowerCase()})</b>. If this is intentional, please select "Confirm"
              below, otherwise update your email and try again.
            </p>
            <div className="daisy-modal-action">
              <div className="flex gap-default">
                <button className="daisy-btn daisy-btn-primary" onClick={handleAcknowledgeDifferentEmail}>
                  Confirm
                </button>
                <button
                  className="daisy-btn daisy-btn-secondary"
                  onClick={() => Utils.closeDialogModal(ACKNOWLEDGE_DIFFERENT_EMAIL_MODAL_ID)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </dialog>

        {/* Get quote modal */}
        <dialog id={GET_QUOTE_MODAL_ID} className="daisy-modal p-content-mobile lg:p-content">
          <div className="daisy-modal-box flex flex-col gap-4 p-6">
            <h1 className="text-xl">Hmm, we don't recognize that email.</h1>
            <p>If you have already accepted a quote from EcoTrove, please use the original email you provided.</p>
            <p>If not, please sign up for an EcoTrove quote below.</p>
            <p>
              Feel free to reach out to{" "}
              <a href="mailto:info@ectrove.com" className="daisy-link-hover daisy-link daisy-link-primary">
                info@ecotrove.com
              </a>{" "}
              with any questions!
            </p>
            <div className="daisy-modal-action">
              <div>
                <button className="daisy-btn daisy-btn-primary mr-2" onClick={handleGetQuote}>
                  Get Quote
                </button>
                {/* This button will automatically close the daisy modal */}
                <button
                  className="daisy-btn daisy-btn-secondary"
                  onClick={() => Utils.closeDialogModal(GET_QUOTE_MODAL_ID)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </dialog>

        <CardWithBackground>
          <h1 className="text-xl">Create Account</h1>
          <form
            className="flex flex-col gap-4"
            onSubmit={createAccount.handleSubmit((data) =>
              handleCreateAccount({
                ...data,
                email: data.email.toLowerCase(),
                showEmailAcknowledgement: true,
              }),
            )}
          >
            {/* Name */}
            <label className="flex w-full flex-col gap-default">
              <div className="flex items-center gap-default">
                <UserCircleIcon className="hero-icon hero-icon-sm" />
                <p>Full Name</p>
              </div>
              <input
                type="text"
                className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                placeholder="Your name"
                aria-invalid={Boolean(errors.name)}
                {...createAccount.register("name", {
                  required: true,
                  pattern: FULL_NAME_REGEX,
                })}
              />
              <RDCValidationError error={errors.name ? "Please enter your full name" : ""} />
            </label>
            {/* Email */}
            <label className="flex w-full flex-col gap-default">
              <div className="flex items-center gap-default">
                <EnvelopeIcon className="hero-icon hero-icon-sm" />
                <p>Email</p>
              </div>
              <input
                type="email"
                className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                placeholder="Your email address"
                aria-invalid={Boolean(errors.email)}
                {...createAccount.register("email", {
                  required: true,
                  pattern: EMAIL_REGEX,
                })}
              />
              <RDCValidationError error={errors.email ? "Please enter a valid email address" : ""} />
            </label>
            {/* Password + confirmation */}
            <RDCConfirmPasswordsInput form={createAccount} />
            {/* Captcha  */}
            <RDCCaptcha
              captchaKey={captchaKey}
              onSuccess={(token) => setCaptchaToken(token)}
              invalid={captchaInvalid}
            />
            <button className="daisy-btn daisy-btn-primary w-full" type="submit" disabled={loading}>
              Create Account
            </button>
          </form>
          <div className="flex flex-col place-items-center gap-default">
            <p>Already have an account?</p>
            <a className="daisy-link-hover daisy-link daisy-link-primary" href={LOGIN_ROUTE}>
              Log in
            </a>
          </div>
        </CardWithBackground>
      </div>
    </>
  );
};
