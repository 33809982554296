import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

export const RDCThanksForReauthorizingPage: React.FC = () => {
  useEffect(() => {
    mixpanel.track("thanksForReauthorizing");
  }, []);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-base-100">
      <div className="text-center">
        <h1 className="text-xl text-black">Thank you for re-authorizing your PG&E account with EcoTrove!</h1>
        <p className="mt-4 text-sm text-gray-700">Your bills are on us.</p>
      </div>
    </div>
  );
};
