import { useEffect } from "react";
import FaceX from "../images/faces/FaceX.svg";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

/**
 * Status codes that can be displayed as a page.
 */
export type StatusCode = 400 | 403 | 404 | 500;

const FACE_X = <img className="size-24" src={FaceX} alt="circular face with x eyes" />;

const CONFIG: Record<StatusCode, { readable: string; icon: JSX.Element; message: string }> = {
  400: {
    readable: "Bad Request",
    icon: FACE_X,
    message: "Sorry, this link is invalid.",
  },
  403: {
    readable: "Access Denied",
    icon: FACE_X,
    message: "Sorry, you don't have access to this page.",
  },
  404: {
    readable: "Not Found",
    icon: FACE_X,
    message: "Sorry, the page you're looking for could not be found.",
  },
  500: {
    readable: "Internal Server Error",
    icon: FACE_X,
    message: "Sorry, something went wrong on our side.",
  },
};

/**
 * Props to pass to a `RDCStatusCodePage`.
 */
export interface RDCStatusCodeProps {
  /**
   * The status code to display for the page.
   */
  code: StatusCode;
  /**
   * Optionally display additional information below the generic message.
   *
   * IMPORTANT: Do not expose sensitive implementation details here.
   */
  additionalInfo?: string;
}

/**
 * Page that displays the specified status code.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCStatusCodePage: React.FC<RDCStatusCodeProps> = (props) => {
  const { code, additionalInfo } = props;
  const { readable, icon, message } = CONFIG[code];

  // Track page visit on Mixpanel
  useEffect(() => {
    // If the status code is 404, add additional information about which URL the user attempted to navigate to
    mixpanel.track(
      `Status code page: ${code}${code === 404 ? ` while attempting to navigate to ${window.location.href}` : ""}`,
    );
  }, [code]);

  return (
    <>
      <Helmet>
        {/* Prevent status code pages from being indexed */}
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="flex min-h-dvh flex-col place-content-center place-items-center gap-6 p-content-mobile text-center lg:p-content">
        {icon}
        <h1 data-testid="status-code" className="text-primary">
          {code}: {readable}
        </h1>
        <h2 className="text-2xl">{message}</h2>
        {additionalInfo && (
          <h3 data-testid="additional-info" className="text-base text-neutral-content">
            Message: {additionalInfo}
          </h3>
        )}
        <a className="daisy-btn daisy-btn-primary" href="/">
          Take Me Home
        </a>
      </main>
    </>
  );
};
