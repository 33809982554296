import mixpanel from "mixpanel-browser";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAlertStore } from "../stores";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { LOGIN_ROUTE, USER_DASHBOARD_ROUTE } from "../constants";
import { CardWithBackground } from "../components";
import { Utils } from "../utils";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

type Answers = Record<string, string | Record<string, boolean>>;
type SurveyMessage = { title: string; subTitle: string };

// Types of questions that can be asked in a survey
type BaseQuestion = {
  key: string;
  message: string;
  subMessage?: string;
  tooltip?: string;
};
type MessageQuestion = BaseQuestion & { type: "message" };
type SingleSelectQuestion = BaseQuestion & {
  type: "singleSelect";
  options: string[];
};
type MultipleSelectQuestion = BaseQuestion & {
  type: "multipleSelect";
  options: string[];
};
type NumericQuestion = BaseQuestion & {
  type: "numeric";
  min: number;
  max: number;
  includeMoreThan?: boolean;
};
type BooleanQuestion = BaseQuestion & { type: "boolean" };
type Question = MessageQuestion | SingleSelectQuestion | MultipleSelectQuestion | NumericQuestion | BooleanQuestion;

const ELIGIBLE_SAVINGS_MESSAGE = "If yes, you might be eligible for additional savings.";
const SURVEY_SAVINGS_RATE = 0.1;

/**
 * List of survey questions to ask users after sign up.
 */
const CONFIG: {
  pre: SurveyMessage;
  post: SurveyMessage;
  pages: Record<string, Question[]>;
} = {
  pre: {
    title: "Before you begin...",
    subTitle: "Help us secure additional savings for you by filling out a survey.",
  },
  post: {
    title: "Thank you!",
    subTitle: "Our team will be in touch if you are eligible for additional savings.",
  },
  pages: {
    // Residential questions
    residential: [
      {
        key: "own_or_rent",
        message: "Do you own or rent your primary residence?",
        type: "singleSelect",
        options: ["Own", "Rent"],
      },
      {
        key: "address_type",
        message: "Please select the option that best describes the property at this address",
        type: "singleSelect",
        options: [
          "Single-Family Home Detached",
          "Single-Family Home Attached",
          "Mobile Home",
          "Multifamily building with 2-4 Units",
          "Multifamily building with 5+ units, 1-3 stories",
          "Multifamily building with 5+ units, 4-7 stories",
          "Multifamily building with 5+ units, 8+ stories",
        ],
      },
    ],
    // Household questions
    household: [
      {
        key: "adults_in_household",
        message: "How many adults (18+) live in your primary residence?",
        type: "numeric",
        min: 0,
        max: 10,
        includeMoreThan: true,
      },
      {
        key: "children_in_household",
        message: "How many children (under 18) live in your primary residence?",
        type: "numeric",
        min: 0,
        max: 10,
        includeMoreThan: true,
      },
      {
        key: "works_from_home",
        message: "Does anyone at this property work from home?",
        type: "boolean",
      },
      {
        key: "in_use_at_address",
        message: "Please select all of the following that you have in use at this address",
        subMessage: "Select all that apply",
        type: "multipleSelect",
        options: [
          "Electric Vehicle",
          "Electric Water Heater",
          "Electric Oven",
          "Garage",
          "Shed With Electricity",
          "Basement",
        ],
      },
    ],
    // Public assistance questions
    "public assistance": [
      {
        key: "has_electric_medical_device",
        message:
          "Does a full-time resident in your household use a qualifying electric-powered medical device at home?",
        subMessage: ELIGIBLE_SAVINGS_MESSAGE,
        type: "boolean",
        tooltip:
          "Qualifying medical devices include, but are not limited to:\n- Aerosol tent\n- Air mattress/hospital bed\n- Apnea monitor\n- Breather machine (IPPB)\n- Compressor/concentrator\n- Dialysis machine\n- Electronic nerve stimulator\n- Electrostatic nebulizer\n- Hemodialysis machine\n- Infusion pump\n- Inhalation pulmonary pressure\n- Iron lung\n- Left ventricular assist device (LVAD)\n- Motorized wheelchair/scooter\n- Oxygen generator\n- Pressure pad\n- Pressure pump\n- Pulse oximeter/monitor\n- Respirator (all types)\n- Suction machine\n- Total artificial heart (TAH-t)\n- Ultrasonic nebulizer\n- Vest/airway clearance system",
      },
      {
        key: "participates_in_qualifying_public_assistance_program",
        message: "Does a full-time resident in your household participate in a qualifying public assistance program?",
        subMessage: ELIGIBLE_SAVINGS_MESSAGE,
        type: "boolean",
        tooltip:
          "Qualifying public assistance programs include, but are not limited to:\n- Low Income Home Energy Assistance Program (LIHEAP)\n- Women, Infants and Children (WIC)\n- CalFresh/SNAP (Food Stamps)\n- CalWORKs(TANF) or Tribal TANF\n- Head Start Income Eligible (Tribal Only)\n- Supplemental Security Income (SSI)\n- Medi-Cal for Families (Healthy Families A & B)\n- National School Lunch Program (NSLP)\n- Bureau of Indian Affairs General Assistance\n- Medicaid/Medi-Cal",
      },
      {
        key: "has_qualifiying_medical_condition",
        message: "Does a full-time resident in your household have a qualifying medical condition?",
        subMessage: ELIGIBLE_SAVINGS_MESSAGE,
        type: "boolean",
        tooltip:
          "Qualifying medical conditions include, but are not limited to:\n- Paraplegic, hemiplegic or quadriplegic condition\n- Multiple sclerosis with heating and/or cooling needs\n- Scleroderma with heating needs\n- Life-threatening illness or compromised immune system\n- Asthma and/or sleep apnea",
      },
      {
        key: "income_range",
        message: "What is your annual household income range?",
        subMessage: "Certain ranges might qualify you for additional savings.",
        type: "singleSelect",
        options: [
          "$40,000 or less",
          "$40,001 - $50,000",
          "$50,001 - $60,000",
          "$60,001 - $70,000",
          "$70,001 - $80,000",
          "$80,001 - $90,000",
          "$90,001 - $100,000",
          "$100,001 - $110,000",
          "$110,001 - $120,000",
          "$120,001 - $130,000",
          "$130,001 - $140,000",
          "$140,001 - $150,000",
          "$150,001 - $160,000",
          "$160,001 or more",
        ],
      },
    ],
  },
};

/**
 * Page that prompts users to answer a list of questions after account generation.
 * @returns a React component.
 */
export const RDCUserQuestionsPage: React.FC = () => {
  const navigate = useNavigate();
  const { setErrorAlert } = useAlertStore();
  const { pre, pages, post } = CONFIG;

  // User state
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [monthlySubscription, setMonthlySubscription] = useState<number | undefined>();

  // Survey state
  const [stage, setStage] = useState<"pre" | "questions" | "post">("pre");
  const [pageNum, setPageNum] = useState<number>(0);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);

  // Tooltip state
  // This is a bit of confusing/unorthodox React code, but what this does is:
  // - track a ref for every tooltip in the survey
  // - if the tooltip is on the right side of the screen, use a left tooltip (and vice versa)
  // - every time the screen is resized, check the tooltip's position and flip its display if needed
  // This is needed because daisy's tooltip can go off the screen depending on the size of the screen
  type TooltipRef = {
    ref: MutableRefObject<HTMLDivElement | null>;
    isOnRight: boolean;
  };
  const initialRefs: Record<string, TooltipRef> = {};
  for (const page of Object.values(pages)) {
    for (const { key, tooltip } of page) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (tooltip) initialRefs[key] = { ref: useRef(null), isOnRight: false };
    }
  }
  const [tooltipRefs, setTooltipRefs] = useState<Record<string, TooltipRef>>(initialRefs);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Listen for window resize events
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Update the tooltip orientation (if needed)
  useEffect(() => {
    const temp: Record<string, TooltipRef> = tooltipRefs;
    for (const [key, { ref }] of Object.entries(temp)) {
      if (ref.current) {
        const { x } = ref.current.getBoundingClientRect();
        const isOnRight = x >= window.innerWidth / 2;
        temp[key] = { ref, isOnRight };
      }
    }
    setTooltipRefs(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, pageNum]);

  // Answers state
  // I chose this pattern over something like react-hook-forms to allow these questions
  // to change easily in the future without having to make any updates to a form object.
  const [answers, setAnswers] = useState<Answers>({});

  useEffect(() => {
    // Track page visit on Mixpanel
    mixpanel.track("userQuestions");

    const fetchData = async () => {
      try {
        // Ensure the user is logged in
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user?.id) {
          navigate(LOGIN_ROUTE, { replace: true });
          return;
        }
        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };
        setAuthenticated(true);
        setUserId(user.id);

        // Retrieve the user's monthly subscription to determine how much we can save from the survey
        const { data } = await supabase
          .from("user-profiles")
          .select("monthly_subscription")
          .eq("user_id", user.id)
          .single();
        if (data?.monthly_subscription) setMonthlySubscription(data.monthly_subscription);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user. Please try again later.");
      }
    };
    fetchData();

    // Prepopulate multi-select answers with an empty object
    const prepopulated: Answers = {};
    for (const page of Object.values(CONFIG.pages)) {
      for (const question of page) {
        if (question.type === "multipleSelect") prepopulated[question.key] = {};
      }
    }
    setAnswers(prepopulated);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable the next button if answers are not filled out
  // NOTE: multi-select questions are intentionally treated as optional via this calculation
  // This allows us to not need a "None of the above" option in our multi-select questions
  useEffect(() => {
    setNextDisabled(Object.values(pages)[pageNum].some((question) => !answers[question.key]));
  }, [answers, pageNum, pages]);

  /**
   * Handler for going to the next page.
   */
  const handleNextPage = async () => {
    setPageNum((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  /**
   * Handler for returning to the previous page.
   */
  const handlePreviousPage = () => {
    setPageNum((prev) => {
      if (prev === 0) {
        setStage("pre");
        return prev;
      } else {
        return prev - 1;
      }
    });
    window.scrollTo(0, 0);
  };

  /**
   * Handler for submitting survey questions.
   */
  const handleSubmit = async () => {
    try {
      setNextDisabled(true);
      const { error } = await supabase
        .from("user-profiles")
        .update({ account_survey: answers, questions_complete: true })
        .eq("user_id", userId);
      if (error) throw error;
      setStage("post");
    } catch (error) {
      setErrorAlert("An error occurred while attempting to submit survey. Please try again later.");
      return;
    } finally {
      setNextDisabled(false);
    }
  };

  /**
   * Helper method that transforms an option into a storeable key.
   */
  const getOptionKey = (option: string): string => {
    return option.toLowerCase().split(/\s+/).join("_");
  };

  /**
   * Helper method that calculates whether the last question page is currently shown.
   */
  const isLastPage = (): boolean => {
    return pageNum >= Object.keys(pages).length - 1;
  };

  return !authenticated ? (
    <></>
  ) : (
    <div>
      <CardWithBackground>
        <div className="flex w-full flex-col items-center gap-12">
          {stage === "pre" || stage === "post" ? (
            <>
              {/* Title (if pre/post survey) */}
              <h1 className="text-base">{stage === "pre" ? pre.title : post.title}</h1>
              <h2 className="text-center text-xl">{stage === "pre" ? pre.subTitle : post.subTitle}</h2>
            </>
          ) : (
            <>
              {/* Progress bar */}
              <div className="flex w-full flex-col gap-default">
                <p className="text-neutral-content">
                  {pageNum + 1}/{Object.keys(pages).length}
                </p>
                <progress
                  className="daisy-progress daisy-progress-primary"
                  value={pageNum + 1}
                  max={Object.keys(pages).length}
                />
              </div>
              <div className="flex w-full flex-col gap-default">
                <h1 className="text-xl">
                  Let's find{" "}
                  {monthlySubscription
                    ? `up to $${Math.round(monthlySubscription * 12 * SURVEY_SAVINGS_RATE)} of annual `
                    : "additional "}
                  savings together.
                </h1>
                <p className="text-base text-neutral-content">
                  Complete your {Object.keys(pages)[pageNum]} details to finalize your account.
                </p>
              </div>
            </>
          )}
          {stage === "questions" &&
            Object.values(pages)[pageNum].map((question, i) => (
              <div key={i} className="flex w-full flex-col gap-default">
                <h2 id="question-message" className={question.type === "message" ? "text-center text-xl" : "text-base"}>
                  {question.message}
                  {question.tooltip && (
                    <div
                      ref={tooltipRefs[question.key].ref}
                      className={`daisy-tooltip daisy-tooltip-secondary mx-1 inline-flex items-baseline ${
                        tooltipRefs[question.key].isOnRight ? "daisy-tooltip-left" : "daisy-tooltip-right"
                      } text-secondary before:text-start before:leading-relaxed`}
                      data-tip={question.tooltip}
                    >
                      <QuestionMarkCircleIcon className="hero-icon hero-icon-sm self-center" />
                      {/* The <p> below is used to align the tooltip properly with the text */}
                      <p className="whitespace-pre" aria-hidden>
                        {" "}
                      </p>
                    </div>
                  )}
                </h2>
                {question.subMessage && (
                  <h3 className="-mt-default mb-default text-sm text-neutral-content">{question.subMessage}</h3>
                )}
                {/* Dropdown questions */}
                {(question.type === "singleSelect" || question.type === "numeric" || question.type === "boolean") && (
                  <select
                    aria-labelledby="question-message"
                    className="daisy-select daisy-select-bordered w-full"
                    // Safe cast: we know these questions will always have string answers
                    value={(answers[question.key] as string) ?? "Select one..."}
                    onChange={(e) =>
                      setAnswers((prev) => ({
                        ...prev,
                        [question.key]: e.target.value,
                      }))
                    }
                  >
                    <option key="default" disabled>
                      Select one...
                    </option>
                    {/* Select options */}
                    {question.type === "singleSelect" &&
                      question.options.map((option, i) => <option key={i}>{option}</option>)}
                    {/* Numeric options */}
                    {question.type === "numeric" &&
                      Utils.range(question.min, question.max)
                        .map((num) => num.toString())
                        .map((option, i) => (
                          <option key={i}>
                            {option}
                            {question.includeMoreThan && i === question.max ? "+" : ""}
                          </option>
                        ))}
                    {/* Boolean options */}
                    {question.type === "boolean" && ["Yes", "No"].map((val, i) => <option key={i}>{val}</option>)}
                    {/* Always allow an opt-out option */}
                    <option key="opt-out">Prefer not to answer</option>
                  </select>
                )}
                {/* Multi-select */}
                {question.type === "multipleSelect" &&
                  question.options.map((option, i) => (
                    <label key={i} className="flex items-center gap-default">
                      <input
                        type="checkbox"
                        // Safe cast: we know these questions will always have record answers
                        defaultChecked={(answers[question.key] as Record<string, boolean>)[getOptionKey(option)]}
                        className="daisy-checkbox-primary daisy-checkbox"
                        value={option}
                        onChange={(e) => {
                          // Safe cast: we know these questions will always have record answers
                          const updated = answers[question.key] as Record<string, boolean>;
                          const optionKey = getOptionKey(e.target.value);
                          if (e.target.checked) {
                            updated[optionKey] = true;
                          } else {
                            delete updated[optionKey];
                          }
                          setAnswers((prev) => ({
                            ...prev,
                            [question.key]: updated,
                          }));
                        }}
                      />
                      {option}
                    </label>
                  ))}
              </div>
            ))}
          {/* Navigation buttons */}
          <div className="flex w-full gap-default">
            {stage === "pre" && (
              <button className="daisy-btn daisy-btn-primary grow" onClick={() => setStage("questions")}>
                Begin
              </button>
            )}
            {stage === "questions" && (
              <>
                <button className="border-default daisy-btn daisy-btn-ghost grow" onClick={handlePreviousPage}>
                  Back
                </button>
                <button
                  className="daisy-btn daisy-btn-primary grow"
                  onClick={isLastPage() ? handleSubmit : handleNextPage}
                  disabled={nextDisabled}
                >
                  {isLastPage() ? "Finish" : "Next"}
                </button>
              </>
            )}
            {stage === "post" && (
              <a className="daisy-btn daisy-btn-primary grow" href={USER_DASHBOARD_ROUTE}>
                Exit Survey
              </a>
            )}
          </div>
        </div>
      </CardWithBackground>
    </div>
  );
};
