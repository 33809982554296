import {
  BanknotesIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  DocumentTextIcon,
  GlobeAmericasIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import {
  RDCCustomizedOffersCard,
  RDCExpandableTable,
  RDCFooter,
  RDCNavbar,
  RDCUsageGraph,
  ServiceData,
} from "../components";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { MonthlyQuote, Plan, Utils } from "../utils";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../constants";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";

interface Bill {
  date: Date;
  paid: boolean;
}

/**
 * Page that displays information about a user's usage and account.
 */
export const RDCUserDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { setErrorAlert } = useAlertStore();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // User data
  const [userId, setUserId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [monthsSinceJoined, setMonthsSinceJoined] = useState<number | undefined>();
  // Service data
  const [electricityData, setElectricityData] = useState<ServiceData>({
    historicalUsage: [],
    estimatedUsage: [],
  });
  const [gasData, setGasData] = useState<ServiceData>({
    historicalUsage: [],
    estimatedUsage: [],
  });
  const [renewableIncrease, setRenewableIncrease] = useState<string | undefined>();
  // Plan data
  const [activePlan, setActivePlan] = useState<Plan>();
  const [monthlyQuote, setMonthlyQuote] = useState<MonthlyQuote>();
  const [monthlySubscription, setMonthlySubscription] = useState<number | undefined>();
  const [quoteUrl, setQuoteUrl] = useState<string>("");
  const [bills, setBills] = useState<Bill[]>([]);

  useEffect(() => {
    mixpanel.track("userDashboard");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Ensure user is logged in
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user?.id) {
          navigate(LOGIN_ROUTE, { replace: true });
          return;
        }

        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

        setAuthenticated(true);
        setUserId(user.id);

        // Fetch user-profiles data
        const { data: userProfileData, error: userProfileError } = await supabase
          .from("user-profiles")
          .select("name,email,start_date,stripe_customer_id,monthly_subscription,monthly_quote,quote_url")
          .eq("user_id", user.id)
          .single();
        if (userProfileError || !userProfileData?.name || !userProfileData?.email) {
          throw new Error("an error occurred");
        }

        // Fetch service-accounts data
        const { data: electricityServiceData } = await supabase
          .from("service-accounts")
          .select("historical_usage,estimated_og_usage,renewable_increase")
          .eq("user_id", user.id)
          .eq("type", "electricity")
          .single();
        setElectricityData({
          historicalUsage: electricityServiceData?.historical_usage ?? [],
          estimatedUsage: electricityServiceData?.estimated_og_usage ?? [],
        });
        if (electricityServiceData?.renewable_increase) {
          setRenewableIncrease((electricityServiceData.renewable_increase / 100).toFixed(1));
        }
        const { data: gasServiceData } = await supabase
          .from("service-accounts")
          .select("historical_usage,estimated_og_usage")
          .eq("user_id", user.id)
          .eq("type", "gas")
          .single();
        setGasData({
          historicalUsage: gasServiceData?.historical_usage ?? [],
          estimatedUsage: gasServiceData?.estimated_og_usage ?? [],
        });

        // Populate user info
        setName(userProfileData.name);
        setEmail(userProfileData.email);
        const tempStartDate = userProfileData.start_date ? new Date(userProfileData.start_date) : undefined;
        // Add a 12 hour offset to the date to address UTC difference
        tempStartDate?.setUTCHours(12);
        setStartDate(tempStartDate);
        setMonthsSinceJoined(tempStartDate ? getMonthsSinceJoined(tempStartDate) : undefined);

        // Populate plan and subscription
        setActivePlan(
          Utils.getPlanName({
            stripeCustomerId: userProfileData.stripe_customer_id,
            monthlyQuote: userProfileData.monthly_quote,
            monthlySubscription: userProfileData.monthly_subscription,
          }),
        );
        setMonthlyQuote(userProfileData.monthly_quote ?? undefined);
        setMonthlySubscription(userProfileData.monthly_subscription ?? undefined);
        setQuoteUrl(userProfileData.quote_url ?? "");

        // Populate bills
        const { data: billsData, error: billsError } = await supabase
          .from("user-bills")
          .select("month,bill_amount")
          .eq("user_id", user.id);
        if (billsError) throw billsError;
        const currentBills = billsData
          // Filter out bills after current month (offset by one month to preview next month's bill)
          .filter(({ month }) => {
            const date = new Date(month);
            date.setUTCMonth(date.getUTCMonth() - 1);
            const now = new Date();
            return (
              date.getUTCFullYear() < now.getUTCFullYear() ||
              (date.getUTCFullYear() === now.getUTCFullYear() && date.getUTCMonth() <= now.getUTCMonth())
            );
          })
          // Filter out bills before start date (offset by one month to account for very first bill)
          .filter(({ month }) => {
            if (!tempStartDate) return true;
            const date = new Date(month);
            date.setUTCMonth(date.getUTCMonth() + 1);
            return (
              date.getUTCFullYear() > tempStartDate.getUTCFullYear() ||
              (date.getUTCFullYear() === tempStartDate.getUTCFullYear() &&
                date.getUTCMonth() >= tempStartDate.getUTCMonth())
            );
          })
          .map(({ month, bill_amount }) => {
            return { date: new Date(month), paid: Boolean(bill_amount) };
          })
          .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
        setBills(currentBills);

        // Add a slight delay to make the loading smoother
        setTimeout(() => setLoading(false), 100);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user. Please try again later.");
      }
    };
    fetchData();
  }, [navigate, userId, setErrorAlert]);

  /**
   * Helper method that calculates the number of months since a user joined.
   * @returns the number of monht.s
   */
  const getMonthsSinceJoined = (startDate: Date): number => {
    const now = new Date();
    const numYears = now.getUTCFullYear() - startDate.getUTCFullYear();
    const numMonths = now.getUTCMonth() - startDate.getUTCMonth();
    return numYears * 12 + numMonths + 1; // add 1 to account for current month
  };

  return !authenticated ? (
    <></>
  ) : (
    <div className="flex flex-col items-center">
      <RDCNavbar />

      <div className="mt-navbar flex w-full max-w-wide grow flex-col gap-8 p-content-mobile lg:p-content">
        {loading ? (
          <div className="daisy-skeleton h-16 lg:w-1/2" />
        ) : (
          <h1 className="text-2xl">Welcome {name.split(" ")[0]}</h1>
        )}
        <div className="grid grow grid-cols-1 gap-8 lg:grid-cols-2">
          {/* Thank you + your data */}
          <div className="lg:border-default flex flex-col gap-4 lg:rounded-3xl lg:p-6">
            <SparklesIcon className="hero-icon min-h-12 text-primary" />
            {loading ? (
              <div className="daisy-skeleton h-[103px]" />
            ) : (
              <h2 className="text-2xl">
                Thank you for using EcoTrove
                {Boolean(monthsSinceJoined) && (
                  <>
                    <br />
                    for{" "}
                    <span className="text-primary">
                      {monthsSinceJoined} month
                      {monthsSinceJoined === 1 ? "" : "s"}
                    </span>
                  </>
                )}
                !
              </h2>
            )}
            {loading ? (
              <div className="daisy-skeleton h-6"></div>
            ) : (
              <ul className="flex flex-col gap-4">
                {[
                  startDate && {
                    icon: <CheckBadgeIcon className="hero-icon hero-icon-sm" />,
                    message: (
                      <p>
                        {activePlan === "Free Insights" ? "Started tracking insights" : "Subscribed"} on{" "}
                        <span className="font-semibold text-base-content">{Utils.getPrettyYear(startDate)}</span>
                      </p>
                    ),
                  },
                  activePlan === "Free Insights" && {
                    icon: <BanknotesIcon className="hero-icon hero-icon-sm" />,
                    message: (
                      <p>
                        Save up to <span className="font-semibold text-base-content">10%*</span> a year by upgrading
                        your plan
                      </p>
                    ),
                  },
                  {
                    icon: <GlobeAmericasIcon className="hero-icon hero-icon-sm" />,
                    message:
                      activePlan === "Free Insights" ? (
                        <p>
                          Use <span className="font-semibold text-base-content">1.5X</span> cleaner energy by upgrading
                          your plan
                        </p>
                      ) : renewableIncrease ? (
                        <p>
                          Helping the planet with{" "}
                          <span className="font-semibold text-base-content">{renewableIncrease}X</span> cleaner energy
                        </p>
                      ) : (
                        <p>We are still calculating your renewable energy increase. Check back soon!</p>
                      ),
                  },
                ].map((value, i) => {
                  return value ? (
                    <li key={i} className="flex items-center gap-default text-neutral-content">
                      {value.icon}
                      {value.message}
                    </li>
                  ) : undefined;
                })}
              </ul>
            )}
            <div className="mt-6 flex items-center gap-default text-neutral-content">
              <ChartBarIcon className="hero-icon hero-icon-sm" />
              <p className="grow">Your Data</p>
            </div>
            {loading ? (
              <div className="daisy-skeleton h-96" />
            ) : (
              <div className="grid h-full grid-cols-1 gap-6 lg:grid-cols-2">
                <RDCUsageGraph
                  modalId="electricity-graph-modal"
                  type="electricity"
                  data={electricityData}
                  startDate={startDate}
                />
                <RDCUsageGraph modalId="gas-graph-modal" type="gas" data={gasData} startDate={startDate} />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-8">
            {/* Customized offers */}
            {loading ? (
              <div className="daisy-skeleton h-56" />
            ) : (
              <RDCCustomizedOffersCard
                userData={{
                  activePlan: activePlan!,
                  email,
                  monthlyQuote,
                  monthlySubscription,
                  quoteUrl,
                }}
              />
            )}
            {/* Active plan */}
            <div className="border-default flex flex-col gap-4 rounded-3xl p-6">
              {loading ? (
                <div className="daisy-skeleton h-8" />
              ) : (
                <div className="flex items-center gap-default">
                  <h2 className="text-lg">{activePlan}</h2>
                  <div className="daisy-badge daisy-badge-neutral">Active plan</div>
                </div>
              )}
              <div className="flex flex-col">
                <div className="flex items-center gap-default text-neutral-content">
                  <BanknotesIcon className="hero-icon hero-icon-sm" />
                  <p>Monthly subscription</p>
                </div>
                {loading ? (
                  <div className="daisy-skeleton h-6" />
                ) : (
                  <p className="ml-8 tracking-wide">
                    {monthlySubscription && activePlan !== "Free Insights"
                      ? `$${monthlySubscription.toFixed(2)}/mo`
                      : "Free"}
                  </p>
                )}
              </div>
              {(activePlan === "Green Saver" || activePlan === "Max Saver") && (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-default text-neutral-content">
                    <DocumentTextIcon className="hero-icon hero-icon-sm" />
                    <p>Utility bills paid</p>
                  </div>
                  {/* Bills paid table */}
                  {loading ? (
                    <div className="daisy-skeleton h-48 w-full" />
                  ) : (
                    <RDCExpandableTable
                      modalId="bill-table-modal"
                      headers={["Date", "Status"]}
                      minRows={3}
                      disabled={!bills.length}
                    >
                      {!bills.length
                        ? [<p className="pt-2 text-sm">No bills yet!</p>]
                        : bills.map(({ date, paid }, i) => (
                            <tr key={i}>
                              <td>
                                <p className="text-sm">
                                  {Utils.getPrettyYear(date, {
                                    excludeDay: true,
                                  })}
                                </p>
                              </td>
                              <td>
                                <p className="text-sm">{paid ? "Paid" : "Not due"}</p>
                              </td>
                            </tr>
                          ))}
                    </RDCExpandableTable>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <RDCFooter showExplainer={activePlan === "Free Insights"} />
    </div>
  );
};
