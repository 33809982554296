import { Utils } from "../../utils";
import { PartnerBannerProps } from "../../components";

/**
 * ID of the modal for Diana's partner banner.
 */
export const DIANA_PARTNER_MODAL_ID = "diana-partner-modal";

/**
 * Config for Diana's partner banner.
 */
export const DIANA_PARTNER_BANNER_CONFIG: PartnerBannerProps = {
  name: "Diana",
  banner: {
    message: "You have received an exclusive discount to EcoTrove courtesy of Diana Saadeh-Jajeh",
    hideVcLogos: true,
  },
  modal: {
    id: DIANA_PARTNER_MODAL_ID,
    image: {
      path: Utils.getPublicPath("images/partners/DianaReferral.png"),
      alt: "EcoTrove's value to businesses",
    },
    header: "Exclusive Discount from Diana",
    body: (
      <>
        <p>Would you stick with your landlord if they charged you random rent prices each month?</p>
        <br />
        <p>
          How about a vendor that starts 3 separate fires in your business for 4 years, and adds a 20% “extraordinary
          increase” on your rent after each of those times – would you happily pay it?
        </p>
        <br />
        <p>
          While your gut might scream “No!” to these hypothetically deceptive vendor relationships, you have a real one
          in your life right now… Power bills. You can save on power and stabilize your bills so that you can put cash
          back into your business, not into your utility.
        </p>
        <br />
        <p>
          At EcoTrove, our goal is simple: we want you to pay less to your utility and know exactly how much your power
          will cost. Our AI Energy Agent optimizes your online utility accounts to replace their unpredictable bills
          with a flat-price subscription to lower-cost energy. Our average customer saves 7% off their power bill, pays
          the same predictable price for power each month and uses cleaner energy.
        </p>
        <br />
        <p>
          You were referred to EcoTrove by Diana Saadeh-Jajeh as part of our Partner Program. As a result, you are
          entitled to an exclusive discount. Connect your utility account with EcoTrove or schedule a call with our
          experts to get started and benefit from your discount.
        </p>
      </>
    ),
  },
};
